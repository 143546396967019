import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Button } from '@mui/material';
import GetAppIcon from '@mui/icons-material/GetApp';
import saveAs from 'file-saver';

import { getAmplifyJwtToken } from '../helpers/auth';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { gql, useApolloClient } from '@apollo/client';
import { useCustomLazyQuery, useCustomQuery } from '../hooks/graphql';
import { isSameDay } from 'date-fns';
import {
  formatDateAndTimeToLargeDate,
  formatDateToLargeDate
} from '../helpers/date';

export const GET_EXPORTS = gql`
  query getExports($clientId: ID!) {
    clientId @client @export(as: "clientId")
    client(clientId: $clientId) {
      id
      exports {
        id
        date
        status
        author
        isDownloadedByAuthor
      }
    }
  }
`;

export const GET_CLIENT_TIMEZONE = gql`
  query getClientTimezone($clientId: ID!) {
    clientId @client @export(as: "clientId")
    client(clientId: $clientId) {
      id
      timezone
    }
  }
`;

const DownloadExportButton = ({ clientId, date, color, format, from, to }) => {
  const client = useApolloClient();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const [downloadInProgress, setDownloadInProgress] = useState(false);

  const { data } = useCustomQuery(GET_CLIENT_TIMEZONE);

  const { loadLazy } = useCustomLazyQuery(GET_EXPORTS, {
    showLoader: false
  });

  const downloadExport = async () => {
    setDownloadInProgress(true);
    const token = await getAmplifyJwtToken();

    const headers = new Headers({
      Authorization: token
    });

    fetch(process.env.REACT_APP_DOWNLOAD_EXPORT_URL, {
      headers,
      method: 'POST',
      body: JSON.stringify({
        clientId,
        date,
        format
      })
    })
      .then(response => {
        if (response.status !== 200) {
          throw response.json();
        }
        return response.json();
      })
      .then(response => {
        if (response.status === 'waiting') {
          enqueueSnackbar(t('toastMessages.downloadExportWarningError'), {
            variant: 'warning'
          });
          loadLazy();
          return;
        }

        if (response.status === 'ok') {
          fetch(response.url, {
            responseType: 'blob'
          })
            .then(response => {
              if (response.status !== 200) {
                throw new Error('Something went wrong.');
              }
              return response.blob();
            })
            .then(blob => {
              const dateRange = isSameDay(new Date(from), new Date(to))
                ? `from_${formatDateAndTimeToLargeDate(
                    from,
                    data.client.timezone
                  )}_to_${formatDateAndTimeToLargeDate(
                    to,
                    data.client.timezone
                  )}`
                : `from_${formatDateToLargeDate(
                    from,
                    data.client.timezone
                  )}_to_${formatDateToLargeDate(to, data.client.timezone)}`;
              saveAs(
                blob,
                `Thank-You_export_${dateRange}_type_${response.fileName}.${format}`
              );

              client.cache.modify({
                id: `ClientExport:${clientId}_${date}`,
                fields: {
                  isDownloadedByAuthor() {
                    return true;
                  }
                }
              });
            })
            .catch(err => {
              throw new Error(`Error! ${err.message}`);
            });
        }
      })
      .catch(err => {
        err.then(errorObject => {
          let errorMessage = t('toastMessages.fetchError');
          if ('error' in errorObject) {
            errorMessage = errorObject.error;
          }
          enqueueSnackbar(errorMessage, { variant: 'error' });
        });
      })
      .finally(() => setDownloadInProgress(false));
  };

  return (
    <Button
      disabled={downloadInProgress}
      variant="text"
      size="small"
      color="secondary"
      startIcon={<GetAppIcon />}
      onClick={downloadExport}
      sx={{
        color: color === 'white' && '#fff'
      }}
    >
      {format}
    </Button>
  );
};

DownloadExportButton.propTypes = {
  color: PropTypes.oneOf(['white', 'green']),
  clientId: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  from: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  format: PropTypes.oneOf(['csv', 'xlsx']).isRequired
};

export default DownloadExportButton;
