import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Grid,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Tooltip,
  Typography,
  Fab
} from '@mui/material';
import MessageIcon from '@mui/icons-material/Message';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-mui';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';

const FeedbackForm = ({ view, username = '' }) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  return (
    <>
      {view === 'text' && (
        <Box component="span" onClick={() => setIsOpen(true)}>
          {t('feedbackForm.title')}
        </Box>
      )}
      {view === 'inline' && (
        <Button size="small" onClick={() => setIsOpen(true)} variant="outlined">
          {t('feedbackForm.title')}
        </Button>
      )}
      {view === 'float' && (
        <Tooltip arrow title={t('feedbackForm.title')}>
          <Fab
            sx={{
              position: 'absolute',
              bottom: 16,
              right: 16,
              color: '#fff'
            }}
            color="primary"
            aria-label="add"
            onClick={() => setIsOpen(true)}
          >
            <MessageIcon />
          </Fab>
        </Tooltip>
      )}

      <Dialog
        maxWidth="sm"
        fullWidth
        onClose={() => setIsOpen(false)}
        open={isOpen}
      >
        <DialogTitle>{t('feedbackForm.title')}</DialogTitle>
        <Formik
          enableReinitialize
          validationSchema={Yup.object().shape({
            body: Yup.string().required(t('commun.form.requiredField')),
            email: Yup.string().required(t('commun.form.requiredField'))
          })}
          initialValues={{
            body: '',
            email: username
          }}
          onSubmit={async (values, { setSubmitting }) => {
            await fetch(process.env.REACT_APP_HUBSPOT_FEEDBACK_FORM_URL, {
              method: 'POST',
              body: JSON.stringify({
                email: values.email,
                body: values.body
              })
            })
              .then(response => {
                if (response.status !== 200) {
                  throw response.json();
                }

                enqueueSnackbar('Votre commentaire a été envoyé avec succès', {
                  variant: 'success'
                });
              })
              .catch(err => {
                err.then(errorObject => {
                  let errorMessage = t('toastMessages.fetchError');
                  if ('error' in errorObject) {
                    errorMessage = errorObject.error;
                  }

                  enqueueSnackbar(errorMessage, { variant: 'error' });
                });
              })
              .finally(() => {
                setIsOpen(false);
                setSubmitting(false);
              });
          }}
        >
          {({ resetForm, isSubmitting, handleSubmit }) => (
            <Form>
              <DialogContent>
                <Grid container spacing={1} direction="column">
                  <Grid item>
                    <Typography variant="body2">
                      {t('feedbackForm.desc_line1')}
                      <br />
                      {t('feedbackForm.desc_line2')}
                    </Typography>
                  </Grid>
                  {username === '' && (
                    <Grid item>
                      <Field
                        component={TextField}
                        type="string"
                        fullWidth
                        name="email"
                        label={t('commun.email')}
                      />
                    </Grid>
                  )}
                  <Grid item>
                    <Field
                      component={TextField}
                      type="string"
                      fullWidth
                      multiline
                      name="body"
                      label={t('feedbackForm.feedback')}
                      rows={4}
                    />
                  </Grid>
                  <Grid item>
                    <Typography variant="body2">
                      {t('feedbackForm.support')}{' '}
                      <a
                        target="_blank"
                        href="mailto:support@thank-you.io"
                        rel="noreferrer"
                      >
                        support@thank-you.io
                      </a>
                      .
                    </Typography>
                  </Grid>
                </Grid>
              </DialogContent>

              <DialogActions>
                <Button
                  disabled={isSubmitting}
                  onClick={() => {
                    resetForm();
                    setIsOpen(false);
                  }}
                  color="secondary"
                >
                  {t('commun.cancel')}
                </Button>
                <Button
                  disabled={isSubmitting}
                  type="submit"
                  color="primary"
                  onClick={handleSubmit}
                >
                  {t('commun.send')}
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </>
  );
};

FeedbackForm.propTypes = {
  view: PropTypes.oneOf(['inline', 'float', 'text']).isRequired,
  username: PropTypes.string
};

export default FeedbackForm;
