export const CONNECTORS_LIST = [
  {
    id: 'googleSearchConsole',
    name: 'Google Search Console',
    type: 'seo',
    logoShort: '/images/connectors/googleSearchConsole_short.svg',
    logoLarge: '/images/connectors/googleSearchConsole.svg',
    status: 'enabled',
    detailsPage: {
      dataGanularity: 'keyword'
    }
  },
  {
    id: 'googleAds',
    name: 'Google Ads',
    type: 'ads',
    logoShort: '/images/connectors/googleAds_short.svg',
    logoLarge: '/images/connectors/googleAds_large.svg',
    status: 'enabled',
    detailsPage: {
      dataGanularity: 'campaign'
    },
    conversions: ['conversionsByConversionDate'],
    showAmount: true
  },
  {
    id: 'microsoftAds',
    name: 'Microsoft Advertising',
    type: 'ads',
    logoShort: '/images/connectors/microsoft.svg',
    logoLarge: '/images/connectors/microsoft.svg',
    status: 'enabled',
    detailsPage: {
      dataGanularity: 'campaign'
    }
  },
  {
    id: 'criteoAds',
    name: 'Criteo',
    type: 'ads',
    status: 'enabled',
    logoShort: '/images/connectors/criteo_short.svg',
    logoLarge: '/images/connectors/criteo.svg',
    detailsPage: {
      dataGanularity: 'campaign'
    }
  },
  {
    id: 'metaAds',
    name: 'Meta Business',
    type: 'ads',
    status: 'enabled',
    logoShort: '/images/connectors/meta_short.svg',
    logoLarge: '/images/connectors/meta_large.svg',
    detailsPage: {
      dataGanularity: 'campaign'
    },
    conversions: ['1d_view', '7d_view', '1d_click', '7d_click', '28d_click'],
    conversionGoals: [
      'link_click',
      'landing_page_view',
      'view_content',
      'add_to_cart',
      'add_to_wishlist',
      'initiate_checkout',
      'add_payment_info',
      'purchase',
      'lead',
      'complete_registration',
      'search',
      'contact',
      'customize_product',
      'donate',
      'find_location',
      'schedule',
      'start_trial',
      'submit_application',
      'subscribe',
      'app_install',
      'app_use',
      'credit_spent'
    ],
    showAmount: true
  },
  {
    id: 'awinAffiliation',
    name: 'Awin',
    type: 'affiliation',
    status: 'enabled',
    logoShort: '/images/connectors/awin_short.svg',
    logoLarge: '/images/connectors/awin_large.svg',
    detailsPage: {
      dataGanularity: 'campaign'
    }
  },
  {
    id: 'affilaeAffiliation',
    name: 'Affilae',
    type: 'affiliation',
    status: 'enabled',
    logoShort: '/images/connectors/affilae_short.svg',
    logoLarge: '/images/connectors/affilae_large.svg',
    detailsPage: {
      dataGanularity: 'campaign'
    }
  },
  {
    id: 'kwankoAffiliation',
    name: 'Kwanko',
    type: 'affiliation',
    status: 'enabled',
    logoShort: '/images/connectors/kwankoAffiliation_short.svg',
    logoLarge: '/images/connectors/kwankoAffiliation_large.svg',
    detailsPage: {
      dataGanularity: 'campaign'
    }
  },
  {
    id: 'effinityAffiliation',
    name: 'Effinity',
    type: 'affiliation',
    status: 'enabled',
    logoShort: '/images/connectors/effinity_short.svg',
    logoLarge: '/images/connectors/effinity_large.svg',
    detailsPage: {
      dataGanularity: 'campaign'
    }
  },
  {
    id: 'rakutenAffiliation',
    name: 'Rakuten',
    type: 'affiliation',
    status: 'enabled',
    logoShort: '/images/connectors/rakuten_short.svg',
    logoLarge: '/images/connectors/rakuten_large.svg',
    detailsPage: {
      dataGanularity: 'campaign'
    }
  },
  {
    id: 'timeone',
    name: 'TimeOne Group',
    type: 'affiliation',
    status: 'disabled',
    logoShort: '/images/connectors/timeone_short.png',
    logoLarge: '/images/connectors/timeone_large.png',
    detailsPage: {
      dataGanularity: 'campaign'
    }
  },
  {
    id: 'tradedoubler',
    name: 'Tradedoubler',
    type: 'affiliation',
    status: 'disabled',
    logoShort: '/images/connectors/tradedoubler_short.png',
    logoLarge: '/images/connectors/tradedoubler_large.png',
    detailsPage: {
      dataGanularity: 'campaign'
    }
  },
  {
    id: 'stackadaptAds',
    name: 'StackAdapt',
    type: 'ads',
    logoShort: '/images/connectors/stackadapt_short.svg',
    logoLarge: '/images/connectors/stackadapt.svg',
    status: 'enabled',
    detailsPage: {
      dataGanularity: 'campaign'
    }
  },
  {
    id: 'rtbhouseAds',
    name: 'RTB House',
    type: 'ads',
    logoShort: '/images/connectors/rtbhouse_short.svg',
    logoLarge: '/images/connectors/rtbhouse.svg',
    status: 'enabled',
    detailsPage: {
      dataGanularity: 'campaign'
    }
  },
  {
    id: 'pinterestAds',
    name: 'Pinterest',
    type: 'ads',
    logoShort: '/images/connectors/pinterestAds_short.svg',
    logoLarge: '/images/connectors/pinterestAds_large.svg',
    status: 'enabled',
    detailsPage: {
      dataGanularity: 'campaign'
    }
  },
  {
    id: 'teadsAds',
    name: 'Teads',
    type: 'ads',
    logoShort: '/images/connectors/teads_short.svg',
    logoLarge: '/images/connectors/teads_large.svg',
    status: 'enabled',
    detailsPage: {
      dataGanularity: 'campaign'
    }
  },
  {
    id: 'tiktokAds',
    name: 'TikTok',
    type: 'ads',
    logoShort: '/images/connectors/tiktokAds_short.svg',
    logoLarge: '/images/connectors/tiktokAds_large.svg',
    status: 'enabled',
    detailsPage: {
      dataGanularity: 'campaign'
    }
  },
  {
    id: 'snapchatAds',
    name: 'Snapchat',
    type: 'ads',
    logoShort: '/images/connectors/snapchatAds.svg',
    logoLarge: '/images/connectors/snapchatAds.svg',
    status: 'enabled',
    detailsPage: {
      dataGanularity: 'campaign'
    },
    conversions: ['conversion_purchase'],
    showAmount: true
  }
];
