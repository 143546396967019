import React, { useEffect, useState } from 'react';
import { useQuery, gql } from '@apollo/client';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

import DownloadExportButton from '../organisms/DownloadExportButton';
import { isWithinInterval, subDays } from 'date-fns';
import { exportShowNotificationMainMenu } from '../context';
import { useAtom } from 'jotai';
import { useCustomQuery } from './graphql';
import { Stack } from '@mui/material';

const GET_LOCAL_CLIENT_ID = gql`
  query LocalClientId {
    clientId @client
  }
`;

export const GET_EXPORTS = gql`
  query getExportsHook($clientId: ID!, $inProgress: Boolean) {
    clientId @client @export(as: "clientId")
    client(clientId: $clientId) {
      id
      exports(inProgress: true) {
        id
        date
        status
        author
        isDownloadedByAuthor
        from
        to
      }
    }
  }
`;

export const useExport = myself => {
  const { t } = useTranslation();
  const [, setShowNotif] = useAtom(exportShowNotificationMainMenu);
  const { enqueueSnackbar } = useSnackbar();
  const [exportInProgressFromAuthor, setExportInProgressFromAuthor] = useState(
    []
  );
  const { data: dataLocalActiveClientId } = useQuery(GET_LOCAL_CLIENT_ID);

  const { data, startPolling, stopPolling } = useCustomQuery(GET_EXPORTS, {
    showLoader: false,
    skip: !dataLocalActiveClientId?.clientId || !myself?.id || myself.isAdmin,
    errorHandling: {
      signOutOnError: true
    }
  });

  useEffect(() => {
    if (
      data?.client?.exports?.some(
        item =>
          item.status === 'done' &&
          !item.isDownloadedByAuthor &&
          item.author === myself?.id &&
          isWithinInterval(new Date(item.date), {
            start: subDays(new Date(), 1),
            end: new Date()
          })
      )
    ) {
      setShowNotif(true);
    } else {
      setShowNotif(false);
    }
  }, [data, setShowNotif, myself?.id]);

  useEffect(() => {
    if (exportInProgressFromAuthor?.length > 0) {
      for (const exportId of exportInProgressFromAuthor) {
        const exportData = data?.client?.exports?.find(
          ({ id }) => id === exportId
        );
        if (exportData?.status === 'done') {
          enqueueSnackbar(t('ma.exports.exportAvailable'), {
            variant: 'success',
            action: (
              <Stack direction="row" spacing={1}>
                <DownloadExportButton
                  color="white"
                  clientId={dataLocalActiveClientId.clientId}
                  date={exportData.date}
                  format="csv"
                  from={exportData.from}
                  to={exportData.to}
                />
                <DownloadExportButton
                  color="white"
                  clientId={dataLocalActiveClientId.clientId}
                  date={exportData.date}
                  format="xlsx"
                  from={exportData.from}
                  to={exportData.to}
                />
              </Stack>
            )
          });
        }
      }
    }
    if (data?.client?.exports?.length > 0) {
      setExportInProgressFromAuthor(
        data?.client?.exports
          ?.filter(
            item =>
              ['waiting', 'inProgress'].includes(item.status) &&
              item.author === myself?.id
          )
          ?.map(({ id }) => id) || []
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, myself?.id]);

  useEffect(() => {
    if (exportInProgressFromAuthor.length > 0) {
      startPolling && startPolling(5000);
    } else {
      stopPolling && stopPolling();
    }
    return () => stopPolling();
  }, [exportInProgressFromAuthor, startPolling, stopPolling]);
};
