import React, { useState, useEffect } from 'react';
import { Auth } from 'aws-amplify';
import PropTypes from 'prop-types';
import {
  ApolloProvider,
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  gql,
  HttpLink
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { subDays } from 'date-fns';

import { formatDateToString } from './helpers/date';

const httpLink = new HttpLink({ uri: process.env.REACT_APP_GRAPHQL_URL });

const authMiddleware = setContext(async (_, { headers }) => {
  try {
    const token = (await Auth?.currentSession())?.getIdToken()?.getJwtToken();
    if (!token) {
      window.location.reload(false);
    }

    return {
      headers: {
        ...headers,
        authorization: token || null
      }
    };
  } catch (err) {
    return {
      headers
    };
  }
});

const query = gql`
  query ClientData {
    globalLoading
    datePickerCompareFrom
    datePickerCompareTo
    datePickerFrom
    datePickerTo
    hoursPickerFrom
    hoursPickerTo
    selectedAffiliatePlatformIds
    dynamicVariables {
      name
      values
    }
    filterSentToPlatform
    filterClosingStatuses
    filterPartnerSelectedAffiliate
  }
`;

const Apollo = ({ children }) => {
  const [apolloClient, setApolloClient] = useState(undefined);

  useEffect(() => {
    const cache = new InMemoryCache({
      typePolicies: {
        ClientVouchersUsedList: {
          keyFields: []
        },
        AttributionPings_Settings_CustomAttribute: {
          keyFields: false
        },
        AttributionPings_Settings_CustomAttribute_EcommerceObject: {
          keyFields: false
        },
        AttributionPings_Settings_Subchannels: {
          keyFields: false
        },
        PingFilter_Rule: {
          keyFields: false
        },
        ClientVoucher: {
          keyFields: []
        },
        ClientClosing: {
          keyFields: []
        },
        AffiliationSettingsAttributionModel_WeightingItem: {
          keyFields: false
        },
        AttributionPings_Settings_CustomModelWeight_SubChannel: {
          keyFields: false
        },
        AttributionPings_Settings_Connector_Channel_AudienceDimensions: {
          keyFields: false
        },
        AttributionPings_Settings_Connector_Channel_AudienceDimensions_Campaign: {
          keyFields: false
        },
        AttributionPings_Settings_BackOfficeImports: {
          keyFields: []
        },
        // Make all the subtypes of "AttributionPings" not having their own keyFields in order to keep everything close
        // to "AttributionPings"  where the filters are kept. When one filter changes, the whole query is invalidated.
        AttributionPings_Participation: {
          keyFields: false
        },
        AttributionPings_AdDetails: {
          keyFields: false
        },
        AttributionPings_AdDetails_Campaign: {
          keyFields: false
        },
        AP_SummaryMetrics: {
          keyFields: false
        },
        AP_SummaryMetrics_Details: {
          keyFields: false
        },
        AP_SummaryMetrics_Delay: {
          keyFields: false
        },
        AP_SummaryMetrics_Touches: {
          keyFields: false
        },
        AP_Metrics_ChannelDetails: {
          keyFields: false
        },
        AP_Metrics_CuttingDetails: {
          keyFields: false
        },
        AP_Metrics_TargetDetails: {
          keyFields: false
        },
        AP_Metrics_TargetDetails_Item: {
          keyFields: false
        },
        AP_Metrics_ObjectWithProba: {
          keyFields: false
        },
        AttributionPings_Interaction: {
          keyFields: false
        },
        AttributionPings_Interaction_Interaction: {
          keyFields: false
        },
        AP_SummaryAttributionMetricsConversionsWithDelay: {
          keyFields: false
        },
        AP_SummaryAttributionMetrics: {
          keyFields: false
        },
        AP_DeviceMetrics: {
          keyFields: false
        },
        AP_DeviceMetrics_Data: {
          keyFields: false
        },
        AP_DeviceMetrics_Data_Detail: {
          keyFields: false
        }
      }
    });

    const client = new ApolloClient({
      link: ApolloLink.from([authMiddleware, httpLink]),
      cache,
      resolvers: {},
      defaultOptions: {
        watchQuery: {
          fetchPolicy: 'cache-and-network'
        }
      }
    });

    cache.writeQuery({
      query,
      data: {
        globalLoading: [],
        datePickerCompareFrom:
          (localStorage.getItem('ty_datepicker_compare_from') !== 'null' &&
            localStorage.getItem('ty_datepicker_compare_from')) ||
          null,
        datePickerCompareTo:
          (localStorage.getItem('ty_datepicker_compare_to') !== 'null' &&
            localStorage.getItem('ty_datepicker_compare_to')) ||
          null,
        datePickerFrom:
          localStorage.getItem('ty_datepicker_from') ||
          formatDateToString(subDays(new Date(), 7)),
        datePickerTo:
          localStorage.getItem('ty_datepicker_to') ||
          formatDateToString(subDays(new Date(), 1)),
        hoursPickerFrom:
          localStorage.getItem('ty_hours_picker_from') || '00:00:00',
        hoursPickerTo: localStorage.getItem('ty_hours_picker_to') || '23:59:59',
        selectedAffiliatePlatformIds:
          JSON.parse(localStorage.getItem('ty_selected_platforms')) || null,
        dynamicVariables: JSON.parse(localStorage.getItem('ty_dv')) || [],
        filterSentToPlatform:
          JSON.parse(localStorage.getItem('ty_filter_sentPlatform')) || [],
        filterClosingStatuses:
          JSON.parse(localStorage.getItem('ty_filter_closingStatuses')) || [],
        filterPartnerSelectedAffiliate:
          JSON.parse(localStorage.getItem('ty_filter_partner_affiliates')) || []
      }
    });

    setApolloClient(client);

    return () => {};
  }, []);

  if (apolloClient === undefined) return null;

  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
};

Apollo.propTypes = {
  children: PropTypes.node.isRequired
};

export default Apollo;
