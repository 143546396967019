import { MdOutlineAutoGraph } from 'react-icons/md';
import { HiOutlineUsers } from 'react-icons/hi';
import { BiCheckShield } from 'react-icons/bi';

export const TY_PRODUCTS = selectedViewId => ({
  analytics: {
    path: `/marketing-analytics/view/${selectedViewId}`,
    marketingWebsiteUrl: 'https://thank-you.io/marketing-analytics',
    name: 'Marketing',
    icon: MdOutlineAutoGraph
  },
  affiliates: {
    path: '/affiliate-marketing',
    marketingWebsiteUrl: 'https://thank-you.io/affiliate-marketing',
    name: 'Affiliate',
    icon: HiOutlineUsers
  },
  brandSafety: {
    path: '/brand-safety',
    marketingWebsiteUrl: 'https://thank-you.io/brand-safety',
    name: 'Brand-Safety',
    icon: BiCheckShield
  }
});
