import { useAtom } from 'jotai';
import { useEffect, useCallback, useContext, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import UserContext, { analyticsFiltersAtom } from '../context';
import { useAnalyticsHelpers } from './analyticsHelpers';

// Custom hook to get all the affiliate categories
export const useAnalyticsFilters = () => {
  let navigate = useNavigate();
  const { clientSettings } = useContext(UserContext);
  const { selectedViewData } = useAnalyticsHelpers();

  const [analyticsFilters, setAnalyticsFilters] = useAtom(analyticsFiltersAtom);

  const updateFilters = useCallback(
    filtersToUpdate => {
      let toSave = [];

      if (analyticsFilters?.length > 0) {
        toSave = analyticsFilters.filter(
          existingFilter =>
            !filtersToUpdate.some(({ type }) => existingFilter.type === type)
        );
      }

      for (const filterToUpdate of filtersToUpdate) {
        if (filterToUpdate.ids.length > 0) {
          toSave.push(filterToUpdate);
        }
      }

      // Remove filters that are already in the selected view
      toSave = toSave
        ?.map(filterToSave => {
          if (
            !selectedViewData?.filters?.some(
              ({ type }) => type === filterToSave.type
            ) ||
            !selectedViewData?.filters
              ?.find(({ type }) => type === filterToSave.type)
              ?.ids?.every(id => filterToSave.ids.includes(id))
          ) {
            return filterToSave;
          }

          return {
            ...filterToSave,
            ids: filterToSave.ids.filter(
              id =>
                !selectedViewData?.filters?.some(
                  ({ type, ids }) =>
                    type === filterToSave.type && ids.includes(id)
                )
            )
          };
        })
        .filter(({ ids }) => ids?.length > 0);

      setAnalyticsFilters(toSave);
    },
    [analyticsFilters, setAnalyticsFilters, selectedViewData]
  );

  // Update URL
  useEffect(() => {
    if (!analyticsFilters) {
      return;
    }

    if (analyticsFilters?.length === 0) {
      if (window.location.href.includes('?')) {
        navigate(window.location.pathname);
      }
      return;
    }

    let params = new URLSearchParams();

    for (const filter of analyticsFilters) {
      params.set(filter.type, filter.ids.join(','));
    }

    if (
      window.location.href !==
      `${window.location.pathname}?${params.toString()}`
    ) {
      navigate(`${window.location.pathname}?${params.toString()}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [analyticsFilters, navigate, window.location.pathname]);

  // On init load when no data
  useEffect(() => {
    if (analyticsFilters) {
      return;
    }

    let params = new URLSearchParams(window.location.search);

    if (!params) {
      return;
    }

    const filtersToInit = [];
    for (const [key, value] of params.entries()) {
      // eslint-disable-next-line no-unused-vars
      const [, _, ...customAttributeIds] = key?.split('_');
      const customAttributeData = clientSettings.attributionPingsSettings.customAttributes.find(
        attr => attr.internalAttributKey === customAttributeIds.join('_')
      );

      if (
        ((key.includes('customAttribute_') ||
          key.includes('audienceDimension_')) &&
          customAttributeData) ||
        !key.includes('customAttribute_')
      ) {
        filtersToInit.push({
          type: key,
          ids: value.split(',')
        });
      }
    }

    updateFilters(filtersToInit);
  }, [analyticsFilters, clientSettings, updateFilters]);

  const toggleSingleFilter = useCallback(
    (filterType, filterId) => {
      if (
        analyticsFilters
          ?.find(({ type }) => type === filterType)
          ?.ids?.includes(filterId)
      ) {
        updateFilters([
          {
            type: filterType,
            ids: analyticsFilters
              .find(({ type }) => type === filterType)
              .ids.filter(id => id !== filterId)
          }
        ]);
      } else {
        updateFilters([
          {
            type: filterType,
            ids: [
              ...(analyticsFilters?.find(({ type }) => type === filterType)
                ?.ids || []),
              filterId
            ]
          }
        ]);
      }
    },
    [analyticsFilters, updateFilters]
  );

  const activateSingleFilter = useCallback(
    (filterType, filterId) => {
      if (
        analyticsFilters
          ?.find(({ type }) => type === filterType)
          ?.ids?.includes(filterId)
      ) {
        return;
      }

      updateFilters([
        {
          type: filterType,
          ids: [
            ...(analyticsFilters?.find(({ type }) => type === filterType)
              ?.ids || []),
            filterId
          ]
        }
      ]);
    },
    [analyticsFilters, updateFilters]
  );

  const resetFilters = useCallback(() => {
    setAnalyticsFilters([]);
  }, [setAnalyticsFilters]);

  const hasFiltersDependingOnAttributionModel = useMemo(() => {
    if (
      analyticsFilters?.length === 0 &&
      selectedViewData?.filters?.length === 0
    ) {
      return false;
    }

    const attribModelFilters = [
      'channel',
      'subChannel',
      'cutting',
      'cuttingContainsOr',
      'cuttingContainsAnd',
      'target',
      'targetNotContainsOr',
      'targetContainsOr',
      'targetContainsAnd',
      'targetNotContainsAnd'
    ];

    return (
      analyticsFilters?.some(({ type }) => attribModelFilters.includes(type)) ||
      selectedViewData?.filters?.some(({ type }) =>
        attribModelFilters.includes(type)
      )
    );
  }, [analyticsFilters, selectedViewData]);

  return {
    activateSingleFilter,
    hasFiltersDependingOnAttributionModel,
    filters: analyticsFilters || [],
    updateFilters,
    toggleSingleFilter,
    resetFilters
  };
};
