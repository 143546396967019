import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import { NavLink as RouterLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Badge, Button, Collapse, ListItem } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

import { theme } from '../../../theme';

const PREFIX = 'NavItem';

const classes = {
  item: `${PREFIX}-item`,
  itemLeaf: `${PREFIX}-itemLeaf`,
  button: `${PREFIX}-button`,
  buttonLeaf: `${PREFIX}-buttonLeaf`,
  titleBold: `${PREFIX}-titleBold`,
  icon: `${PREFIX}-icon`,
  title: `${PREFIX}-title`,
  active: `${PREFIX}-active`,
  subItemsContainer: `${PREFIX}-subItemsContainer`
};

const StyledListItem = styled(ListItem)(({ theme }) => ({
  [`&.${classes.item}`]: {
    display: 'block',
    paddingTop: 0,
    paddingBottom: 0
  },

  [`&.${classes.itemLeaf}`]: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0
  },

  [`& .${classes.button}`]: {
    color: theme.palette.text.secondary,
    padding: '5px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%'
  },

  [`& .${classes.buttonLeaf}`]: {
    color: theme.palette.text.secondary,
    padding: '5px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    fontWeight: theme.typography.fontWeightRegular
  },

  [`& .${classes.icon}`]: {
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1)
  },

  [`& .${classes.title}`]: {
    marginRight: 'auto',
    textTransform: 'none'
  },

  [`& .${classes.titleBold}`]: {
    marginRight: 'auto',
    textTransform: 'none',
    fontWeight: theme.typography.fontWeightMedium
  },

  [`& .${classes.active}`]: {
    color: theme.palette.primary.main,
    '& $title': {
      fontWeight: theme.typography.fontWeightMedium
    },
    '& $icon': {
      color: theme.palette.primary.main
    }
  },

  [`& .${classes.subItemsContainer}`]: {
    marginBottom: theme.spacing(1)
  }
}));

function NavItem({
  title,
  href,
  depth,
  children,
  icon: Icon,
  showBadge,
  open: openProp,
  info: Info,
  activeIncludes,
  onClick,
  TextComponent,
  ...rest
}) {
  const [open, setOpen] = useState(openProp);
  let location = useLocation();

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  let paddingLeft = 8;

  if (depth > 0) {
    paddingLeft = 32 + 8 * depth;
  }

  const style = { paddingLeft };

  if (children) {
    if (!href) {
      return (
        <StyledListItem
          className={classes.item}
          disableGutters
          key={title}
          {...rest}
        >
          <Button
            className={classes.button}
            onClick={handleToggle}
            style={style}
          >
            {Icon && (
              <Badge
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'left'
                }}
                color="primary"
                variant="dot"
                invisible={showBadge !== true}
              >
                <Icon className={classes.icon} size="20" />
              </Badge>
            )}
            <span className={depth === 0 ? classes.titleBold : classes.title}>
              {title}
            </span>
            {open ? (
              <ExpandLessIcon size="small" color="inherit" />
            ) : (
              <ExpandMoreIcon size="small" color="inherit" />
            )}
          </Button>
          <Collapse className={classes.subItemsContainer} in={open}>
            {children}
          </Collapse>
        </StyledListItem>
      );
    }

    return (
      <StyledListItem
        className={classes.item}
        disableGutters
        key={title}
        {...rest}
      >
        <Button
          end
          className={classes.buttonLeaf}
          component={RouterLink}
          style={({ isActive }) => ({
            ...style,
            ...(isActive || location.pathname.includes(activeIncludes)
              ? {
                  color: theme.palette.primary.main,
                  '& $title': {
                    fontWeight: theme.typography.fontWeightMedium
                  },
                  '& $icon': {
                    color: theme.palette.primary.main
                  }
                }
              : null)
          })}
          to={href}
        >
          {Icon && (
            <Badge
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left'
              }}
              color="primary"
              variant="dot"
              invisible={showBadge !== true}
            >
              <Icon className={classes.icon} size="20" />
            </Badge>
          )}
          <span className={depth === 0 ? classes.titleBold : classes.title}>
            {title}
          </span>
        </Button>

        {children}
      </StyledListItem>
    );
  }

  if (onClick) {
    return (
      <StyledListItem
        className={classes.itemLeaf}
        disableGutters
        key={title}
        {...rest}
      >
        <Button className={classes.buttonLeaf} style={style} onClick={onClick}>
          {Icon && (
            <Badge
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left'
              }}
              color="primary"
              variant="dot"
              invisible={showBadge !== true}
            >
              <Icon className={classes.icon} size="20" />
            </Badge>
          )}
          <span className={depth === 0 ? classes.titleBold : classes.title}>
            {title}
          </span>
          {Info && <Info className={classes.info} />}
        </Button>
      </StyledListItem>
    );
  }

  if (TextComponent) {
    return (
      <StyledListItem
        className={classes.itemLeaf}
        disableGutters
        key={title}
        {...rest}
      >
        <Button className={classes.buttonLeaf} style={style} onClick={onClick}>
          {Icon && (
            <Badge
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left'
              }}
              color="primary"
              variant="dot"
              invisible={showBadge !== true}
            >
              <Icon className={classes.icon} size="20" />
            </Badge>
          )}
          <span className={depth === 0 ? classes.titleBold : classes.title}>
            {TextComponent()}
          </span>
          {Info && <Info className={classes.info} />}
        </Button>
      </StyledListItem>
    );
  }

  return (
    <StyledListItem
      className={classes.itemLeaf}
      disableGutters
      key={title}
      {...rest}
    >
      <Button
        end
        className={classes.buttonLeaf}
        component={RouterLink}
        style={({ isActive }) => ({
          ...style,
          ...(isActive || location.pathname.includes(activeIncludes)
            ? {
                color: theme.palette.primary.main,
                '& $title': {
                  fontWeight: theme.typography.fontWeightMedium
                },
                '& $icon': {
                  color: theme.palette.primary.main
                }
              }
            : null)
        })}
        to={href}
      >
        {Icon && (
          <Badge
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'left'
            }}
            color="primary"
            variant="dot"
            invisible={showBadge !== true}
          >
            <Icon className={classes.icon} size="20" />
          </Badge>
        )}
        <span className={depth === 0 ? classes.titleBold : classes.title}>
          {title}
        </span>
        {Info && <Info className={classes.info} />}
      </Button>
    </StyledListItem>
  );
}

NavItem.propTypes = {
  activeIncludes: PropTypes.string,
  children: PropTypes.node,
  showBadge: PropTypes.bool,
  depth: PropTypes.number.isRequired,
  href: PropTypes.string,
  icon: PropTypes.any,
  info: PropTypes.any,
  open: PropTypes.bool,
  onClick: PropTypes.func,
  TextComponent: PropTypes.func,
  title: PropTypes.string.isRequired
};

NavItem.defaultProps = {
  open: false,
  activeIncludes: null,
  TextComponent: null,
  onClick: null
};

export default NavItem;
