import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';

function Logo({ size }) {
  return (
    <Box sx={{ position: 'relative' }}>
      {(size === 'xsmall' && (
        <img alt="Logo" height="20px" src={`/images/logo_small.svg`} />
      )) ||
        (size === 'small' && (
          <img alt="Logo" height="40px" src={`/images/logo_small.svg`} />
        )) || <img alt="Logo" height="60px" src={`/images/logo.svg`} />}
    </Box>
  );
}

Logo.defaultProps = {
  admin: false,
  size: 'medium'
};

Logo.propTypes = {
  size: PropTypes.oneOf(['xsmall', 'small', 'medium']),
  admin: PropTypes.bool
};

export default React.memo(Logo);
