import React from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { AppBar, Hidden, IconButton, Toolbar, SvgIcon } from '@mui/material';
import { Menu as MenuIcon } from 'react-feather';

const PREFIX = 'TopBar';

const classes = {
  root: `${PREFIX}-root`,
  toolbar: `${PREFIX}-toolbar`,
  menuButton: `${PREFIX}-menuButton`
};

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  [`&.${classes.root}`]: {
    boxShadow: 'none',
    backgroundColor: theme.palette.primary.main
  },

  [`& .${classes.toolbar}`]: {
    minHeight: 64
  },

  [`& .${classes.menuButton}`]: {
    color: '#fff'
  }
}));

function TopBar({ className, onMobileNavOpen, ...rest }) {
  return (
    <Hidden lgUp>
      <StyledAppBar className={classes.root} {...rest}>
        <Toolbar className={classes.toolbar}>
          <IconButton
            className={classes.menuButton}
            color="inherit"
            onClick={onMobileNavOpen}
            size="large"
          >
            <SvgIcon fontSize="small">
              <MenuIcon />
            </SvgIcon>
          </IconButton>
        </Toolbar>
      </StyledAppBar>
    </Hidden>
  );
}

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func
};

export default TopBar;
