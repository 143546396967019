// Function that take any string and return a clean version
// Useful for creating an ID from a string
export const cleanString = string => string.toLowerCase();

export const truncateWithEllipses = (text, max) =>
  text.substr(0, max - 1) + (text.length > max ? '...' : '');

// Function that take any string and return a clean version
// Useful for creating an ID from a string
export const stringToId = string =>
  string.replace(/[^a-z0-9]/i, '_').toLowerCase();

export const sanitizeUrl = string => {
  try {
    return decodeURIComponent(
      string
        .toLowerCase()
        .replace(/\+|\(|\)|-/g, '')
        .replace(/\s/g, '')
    )
      .replace(/\+|\(|\)|-/g, '')
      .replace(/\s/g, '');
  } catch {
    return string;
  }
};
