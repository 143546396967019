import { useQuery, useMutation, useLazyQuery } from '@apollo/client';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useSnackbar } from 'notistack';
import uuid from 'react-uuid';
import { useTranslation } from 'react-i18next';
import { Auth } from 'aws-amplify';
import { useAtom } from 'jotai';
// eslint-disable-next-line import/no-named-as-default
import posthog from 'posthog-js';

import UserContext, { analyticsFiltersAtom } from '../context';
import { sendErrorToSentry } from '../helpers/sentry';
import { useLocalApolloData } from './localApolloClient';
import { useParams } from 'react-router-dom';
import { getFetchPolicy, getPollingInterval } from '../helpers/date';

export const useCustomMutation = (
  mutation,
  { errorHandling, onCompleted, successMessage, ...mutationParams } = {}
) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const userContextData = useContext(UserContext);

  return useMutation(mutation, {
    ...mutationParams,
    variables: {
      ...(mutationParams?.variables || {}),
      clientId:
        mutationParams?.variables?.clientId ||
        userContextData?.clientSettings?.id ||
        null
    },
    onCompleted(data, clientOptions) {
      if (successMessage) {
        enqueueSnackbar(successMessage, {
          variant: 'success'
        });
      }
      if (onCompleted) {
        onCompleted(data, clientOptions);
      }

      if (
        process.env.NODE_ENV === 'production' &&
        clientOptions?.mutation?.definitions?.length > 0
      ) {
        for (const mutationDef of clientOptions.mutation.definitions) {
          posthog.capture(mutationDef?.name?.value, {
            data: JSON.stringify(clientOptions?.variables)
          });
        }
      }
    },
    onError(error) {
      if (errorHandling?.sendToSentry !== false) {
        sendErrorToSentry(error, userContextData, 'graphqlMutationError');
      }

      if (errorHandling?.function) {
        errorHandling.function(error);
      }

      if (!errorHandling?.hideDefaultMessage) {
        enqueueSnackbar(
          errorHandling?.toastMessage ||
            t('toastMessages.graphqlMutationError'),
          {
            variant: 'error'
          }
        );
      }
    }
  });
};

export const useCustomQuery = (
  query,
  {
    errorHandling,
    showLoader,
    useAdaptivePolling = false,
    useAdaptiveFetchPolicy = false,
    pollInterval = 0,
    fetchPolicy = 'cache-and-network',
    skip,
    ...queryParams
  } = {}
) => {
  const [timeoutRetries, setTimeoutRetries] = useState(0);
  const { t } = useTranslation();
  const {
    reduceGlobalLoading,
    localDateFrom,
    localDateTo
  } = useLocalApolloData();
  const [analyticsFilters] = useAtom(analyticsFiltersAtom);
  let { channelId } = useParams();

  const id = useMemo(() => {
    return uuid();
  }, []);

  const { enqueueSnackbar } = useSnackbar();
  const userContextData = useContext(UserContext);

  const needsSpecificVariable = useCallback(
    filterId =>
      query?.definitions?.some(definition =>
        definition?.variableDefinitions?.some(
          variableDefinition =>
            variableDefinition?.variable?.name?.value === filterId
        )
      ),
    [query]
  );

  const variables = useMemo(
    () => ({
      ...(queryParams?.variables || {}),
      ...(needsSpecificVariable('filters')
        ? {
            filters: [
              ...(queryParams?.variables?.filters || analyticsFilters || []),
              ...((channelId && [
                {
                  type: 'channel',
                  ids: [channelId]
                }
              ]) ||
                [])
            ]
          }
        : {}),
      ...(needsSpecificVariable('timezone')
        ? {
            timezone:
              queryParams?.variables?.timezone ||
              userContextData?.clientSettings?.timezone
          }
        : {}),
      ...(needsSpecificVariable('viewId')
        ? {
            viewId: userContextData?.selectedView
          }
        : {}),
      ...(needsSpecificVariable('detailsGranularity')
        ? {
            detailsGranularity:
              queryParams?.variables?.detailsGranularity || '1d'
          }
        : {}),
      ...(needsSpecificVariable('clientId')
        ? {
            clientId:
              queryParams?.variables?.clientId ||
              userContextData?.clientSettings?.id
          }
        : {}),
      ...(needsSpecificVariable('conversionMethodology')
        ? {
            conversionMethodology:
              queryParams?.variables?.conversionMethodology ||
              userContextData?.clientSettings?.attributionPingsSettings
                .conversionMethodology
          }
        : {}),
      ...(needsSpecificVariable('visitDefinition')
        ? {
            visitDefinition:
              queryParams?.variables?.visitDefinition ||
              userContextData?.clientSettings?.attributionPingsSettings
                .visitDefinition
          }
        : {}),
      ...(needsSpecificVariable('from')
        ? {
            from: queryParams?.variables?.from || localDateFrom
          }
        : {}),
      ...(needsSpecificVariable('to')
        ? {
            to: queryParams?.variables?.to || localDateTo
          }
        : {}),
      ...(needsSpecificVariable('conversionRateBase')
        ? {
            conversionRateBase:
              queryParams?.variables?.conversionRateBase ||
              userContextData?.clientSettings?.conversionRateBase
          }
        : {})
    }),
    [
      analyticsFilters,
      channelId,
      localDateFrom,
      localDateTo,
      needsSpecificVariable,
      queryParams?.variables,
      userContextData?.selectedView,
      userContextData?.clientSettings?.attributionPingsSettings
        .conversionMethodology,
      userContextData?.clientSettings?.attributionPingsSettings.visitDefinition,
      userContextData?.clientSettings?.conversionRateBase,
      userContextData?.clientSettings?.id,
      userContextData?.clientSettings?.timezone
    ]
  );

  pollInterval =
    useAdaptivePolling && variables?.from && variables?.to
      ? getPollingInterval(variables?.from, variables?.to)
      : pollInterval;

  fetchPolicy =
    useAdaptiveFetchPolicy && variables?.to
      ? getFetchPolicy(variables?.to)
      : fetchPolicy;

  const {
    networkStatus,
    data,
    previousData,
    error,
    startPolling,
    ...restQuery
  } = useQuery(query, {
    ...queryParams,
    skip,
    variables,
    pollInterval: document.visibilityState === 'visible' ? pollInterval : 0,
    fetchPolicy,
    notifyOnNetworkStatusChange: true,
    onError(error) {
      reduceGlobalLoading('remove', id);

      posthog.capture('appGraphqlQueryError', {
        variables,
        query: JSON.stringify(query)
      });

      // If timeout error, then retry because ES cache might be more efficient next time
      if (
        error?.message?.includes('Execution timed out.') &&
        timeoutRetries < 5
      ) {
        setTimeoutRetries(timeoutRetries + 1);
        restQuery.refetch();

        sendErrorToSentry(
          error,
          userContextData,
          `graphqlQuery_Timeout_Refresh_${timeoutRetries}`
        );
      } else {
        if (errorHandling?.sendToSentry !== false) {
          sendErrorToSentry(error, userContextData, 'graphqlQueryError');
        }

        if (errorHandling?.function) {
          errorHandling.function(error);
        }

        if (errorHandling?.signOutOnError) {
          Auth.signOut();
        }

        enqueueSnackbar(
          errorHandling?.toastMessage || t('toastMessages.graphqlQueryError'),
          {
            variant: 'error'
          }
        );
      }
    }
  });

  const onVisibilityChange = () => {
    if (document.visibilityState === 'visible') {
      startPolling(pollInterval);
    }
  };

  useEffect(() => {
    document.addEventListener('visibilitychange', onVisibilityChange);

    return () =>
      document.removeEventListener('visibilitychange', onVisibilityChange);
  });

  useEffect(() => {
    if (showLoader !== false && !skip) {
      if ([1, 2, 3, 4].includes(networkStatus)) {
        reduceGlobalLoading('add', id);
      }

      if ([7, 8].includes(networkStatus)) {
        if (!reduceGlobalLoading('isExisting', id)) {
          reduceGlobalLoading('add', id);
          setTimeout(() => {
            reduceGlobalLoading('remove', id);
          }, 100);
        } else {
          reduceGlobalLoading('remove', id);
        }
      }
    }

    return () => {
      if (showLoader !== false && !skip) {
        reduceGlobalLoading('remove', id);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    id,
    showLoader,
    networkStatus,
    reduceGlobalLoading,
    window.location.href
  ]);

  return {
    networkStatus,
    data: error ? null : data || previousData,
    startPolling,
    error,
    ...restQuery
  };
};

export const useCustomLazyQuery = (
  query,
  { errorHandling, showLoader, ...queryParams } = {}
) => {
  const {
    reduceGlobalLoading,
    localDateFrom,
    localDateTo
  } = useLocalApolloData();
  let { channelId } = useParams();
  const userContextData = useContext(UserContext);
  const [analyticsFilters] = useAtom(analyticsFiltersAtom);
  const id = useMemo(() => {
    return uuid();
  }, []);

  const needsSpecificVariable = useCallback(
    filterId =>
      query?.definitions?.some(definition =>
        definition?.variableDefinitions?.some(
          variableDefinition =>
            variableDefinition?.variable?.name?.value === filterId
        )
      ),
    [query]
  );

  const variables = useMemo(
    () => ({
      ...(queryParams?.variables || {}),
      ...(needsSpecificVariable('filters')
        ? {
            filters: [
              ...(queryParams?.variables?.filters || analyticsFilters || []),
              ...((channelId && [
                {
                  type: 'channel',
                  ids: [channelId]
                }
              ]) ||
                [])
            ]
          }
        : {}),
      ...(needsSpecificVariable('timezone')
        ? {
            timezone:
              queryParams?.variables?.timezone ||
              userContextData?.clientSettings?.timezone
          }
        : {}),
      ...(needsSpecificVariable('clientId')
        ? {
            clientId:
              queryParams?.variables?.clientId ||
              userContextData?.clientSettings?.id
          }
        : {}),
      ...(needsSpecificVariable('detailsGranularity')
        ? {
            detailsGranularity:
              queryParams?.variables?.detailsGranularity || '1d'
          }
        : {}),
      ...(needsSpecificVariable('conversionMethodology')
        ? {
            conversionMethodology:
              queryParams?.variables?.conversionMethodology ||
              userContextData?.clientSettings?.attributionPingsSettings
                .conversionMethodology
          }
        : {}),
      ...(needsSpecificVariable('visitDefinition')
        ? {
            visitDefinition:
              queryParams?.variables?.visitDefinition ||
              userContextData?.clientSettings?.attributionPingsSettings
                .visitDefinition
          }
        : {}),
      ...(needsSpecificVariable('from')
        ? {
            from: queryParams?.variables?.from || localDateFrom
          }
        : {}),
      ...(needsSpecificVariable('to')
        ? {
            to: queryParams?.variables?.to || localDateTo
          }
        : {}),
      ...(needsSpecificVariable('conversionRateBase')
        ? {
            conversionRateBase:
              queryParams?.variables?.conversionRateBase ||
              userContextData?.clientSettings?.conversionRateBase
          }
        : {})
    }),
    [
      analyticsFilters,
      channelId,
      localDateFrom,
      localDateTo,
      needsSpecificVariable,
      queryParams?.variables,
      userContextData?.clientSettings?.attributionPingsSettings
        .conversionMethodology,
      userContextData?.clientSettings?.attributionPingsSettings.visitDefinition,
      userContextData?.clientSettings?.conversionRateBase,
      userContextData?.clientSettings?.id,
      userContextData?.clientSettings?.timezone
    ]
  );

  const [loadLazy, { networkStatus, data, error, ...restQuery }] = useLazyQuery(
    query,
    {
      ...queryParams,
      variables,
      notifyOnNetworkStatusChange: true,
      onError() {
        reduceGlobalLoading('remove', id);

        posthog.capture('appGraphqlQueryError', {
          variables,
          query: JSON.stringify(query)
        });

        if (errorHandling?.signOutOnError) {
          Auth.signOut();
        }
      }
    }
  );

  useEffect(() => {
    if (showLoader !== false) {
      if ([1, 2, 3, 4].includes(networkStatus)) {
        reduceGlobalLoading('add', id);
      }

      if ([7, 8].includes(networkStatus)) {
        reduceGlobalLoading('remove', id);
      }

      return () => {
        reduceGlobalLoading('remove', id);
      };
    }
  }, [id, showLoader, networkStatus, reduceGlobalLoading]);

  return {
    loadLazy,
    data: error ? null : data,
    error,
    ...restQuery
  };
};
