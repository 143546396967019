import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Box, Alert, Typography, CircularProgress, Grid } from '@mui/material';

import { useTranslation } from 'react-i18next';
import { useLocalApolloData } from '../hooks/localApolloClient';

function LoaderAsync({ shouldForceShowing }) {
  const { t } = useTranslation();
  const [isInMinShowTime, setIsInMinShowTime] = useState(false);
  const { localGlobalLoading } = useLocalApolloData();

  useEffect(() => {
    if (!shouldForceShowing) {
      if (localGlobalLoading.length > 0) {
        setIsInMinShowTime(true);
      } else {
        setTimeout(() => setIsInMinShowTime(false), 1000);
      }
    }
  }, [localGlobalLoading, shouldForceShowing]);

  if (
    !shouldForceShowing &&
    localGlobalLoading.length === 0 &&
    !isInMinShowTime
  ) {
    return null;
  }

  // return null;

  return (
    <Box
      sx={{
        position: 'fixed',
        width: '300px',
        bottom: '20px',
        left: '0px',
        right: '0px',
        zIndex: '9999',
        margin: 'auto',
        background: 'transparent'
      }}
    >
      <Box
        sx={{
          borderRadius: '4px'
        }}
      >
        <Alert
          icon={false}
          severity="success"
          sx={{
            justifyContent: 'center',
            color: '#fff',
            backgroundColor: '#00CD8D',
            boxShadow:
              '0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)'
          }}
        >
          <Grid container spacing={2} alignItems="center">
            <Grid item>
              <CircularProgress
                size={20}
                disableShrink
                sx={{ color: '#fff' }}
              />
            </Grid>
            <Grid item>
              <Typography sx={{ fontSize: '14px' }}>
                {t('commun.dataLoading')}
              </Typography>
            </Grid>
          </Grid>
        </Alert>
      </Box>
    </Box>
  );
}

LoaderAsync.defaultProps = {
  shouldForceShowing: false
};

LoaderAsync.propTypes = {
  shouldForceShowing: PropTypes.bool
};

export default React.memo(LoaderAsync);
