import React from 'react';
import {
  Grid,
  Box,
  Link,
  Breadcrumbs as MBreadcrumbs,
  Typography
} from '@mui/material';
import PropTypes from 'prop-types';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Link as RouterLink } from 'react-router-dom';

const Breadcrumbs = ({ links, title, actions, subtitle }) => (
  <Box mb={3}>
    <Grid
      container
      justifyContent="space-between"
      alignItems="flex-end"
      spacing={3}
    >
      <Grid item>
        {links?.length > 0 && (
          <MBreadcrumbs
            separator={<NavigateNextIcon fontSize="small" />}
            aria-label="breadcrumb"
          >
            {links.map(link =>
              link?.link ? (
                <Link
                  key={link.link}
                  variant="body1"
                  color="inherit"
                  to={link.link}
                  component={RouterLink}
                  underline="hover"
                >
                  {link.title}
                </Link>
              ) : (
                <Typography
                  key={link.title}
                  variant="body1"
                  color="textPrimary"
                >
                  {link.title}
                </Typography>
              )
            )}
            <Typography variant="body1" color="textPrimary" />
          </MBreadcrumbs>
        )}
        {title && (
          <Typography variant="h2" color="textPrimary">
            {title}
          </Typography>
        )}
        {subtitle && (
          <Typography variant="subtitle1" color="textPrimary">
            {subtitle}
          </Typography>
        )}
      </Grid>
      {actions && <Grid item>{actions}</Grid>}
    </Grid>
  </Box>
);

Breadcrumbs.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      link: PropTypes.string,
      title: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired
    })
  ),
  title: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  subtitle: PropTypes.string,
  actions: PropTypes.node
};

export default Breadcrumbs;
