import React, { useEffect } from 'react';
import { styled } from '@mui/material/styles';
import NProgress from 'nprogress';
import { Box, LinearProgress } from '@mui/material';

const Root = styled('div')(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  justifyContent: 'center',
  minHeight: '100%',
  margin: theme.spacing(3)
}));

function LoadingScreen() {
  useEffect(() => {
    NProgress.start();

    return () => {
      NProgress.done();
    };
  }, []);

  return (
    <Root>
      <Box width="100%" maxWidth={400}>
        <LinearProgress />
      </Box>
    </Root>
  );
}

export default LoadingScreen;
