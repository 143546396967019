import { graphColors } from '../theme';

export const FRAUDS = {
  inStore: {
    type: 'transactional',
    name: 'am.frauds.inStore.name_one',
    namePlural: 'am.frauds.inStore.name_other',
    color: graphColors[0],
    legendName: 'am.frauds.inStore.legend',
    frenchDefinition: 'am.frauds.inStore.def',
    detailsPage: true
  },
  duplicated: {
    type: 'transactional',
    name: 'am.frauds.duplicated.name_one',
    namePlural: 'am.frauds.duplicated.name_other',
    color: graphColors[1],
    legendName: 'am.frauds.duplicated.legend',
    frenchDefinition: 'am.frauds.duplicated.def',
    detailsPage: true
  },
  noTransaction: {
    type: 'transactional',
    name: 'am.frauds.noTransaction.name_one',
    namePlural: 'am.frauds.noTransaction.name_other',
    color: graphColors[2],
    legendName: 'am.frauds.noTransaction.legend',
    frenchDefinition: 'am.frauds.noTransaction.def',
    detailsPage: true
  },
  noVisit: {
    type: 'transactional',
    name: 'am.frauds.noVisit.name_one',
    namePlural: 'am.frauds.noVisit.name_other',
    color: graphColors[3],
    legendName: 'am.frauds.noVisit.legend',
    frenchDefinition: 'am.frauds.noVisit.def',
    detailsPage: true
  },
  transactionalCookieDropping: {
    type: 'transactional',
    name: 'am.frauds.transactionalCookieDropping.name_one',
    nameLarge: 'am.frauds.transactionalCookieDropping.name_large',
    namePlural: 'am.frauds.transactionalCookieDropping.name_other',
    color: graphColors[4],
    legendName: 'am.frauds.transactionalCookieDropping.legend',
    frenchDefinition: 'am.frauds.transactionalCookieDropping.def',
    detailsPage: true
  },
  cookieDropping: {
    type: 'visit',
    name: 'am.frauds.cookieDropping.name_one',
    namePlural: 'am.frauds.cookieDropping.name_other',
    color: graphColors[5],
    legendName: 'am.frauds.cookieDropping.legend',
    frenchDefinition: 'am.frauds.cookieDropping.def',
    detailsPage: true
  }
};
