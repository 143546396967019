import React, { useState, useContext } from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import NavBar from './NavBar';
import TopBar from './TopBar';

import UserContext from '../../context';
import { rbac } from '../../rbac';

const PREFIX = 'DashboardLayout';

const classes = {
  wrapper: `${PREFIX}-wrapper`,
  contentContainer: `${PREFIX}-contentContainer`,
  content: `${PREFIX}-content`
};

const Root = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.dark,
  display: 'flex',
  height: '100%',
  overflow: 'hidden',
  width: '100%',

  [`& .${classes.wrapper}`]: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: 64,
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 256
    }
  },

  [`& .${classes.contentContainer}`]: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden'
  },

  [`& .${classes.content}`]: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto'
  }
}));

function DashboardLayout({ children }) {
  const { userGroup } = useContext(UserContext);

  const [isMobileNavOpen, setMobileNavOpen] = useState(false);

  if (!rbac['accessAdminLayout'].includes(userGroup)) {
    return <p>You are not authorized to see this page!</p>;
  }

  return (
    <Root>
      <TopBar onMobileNavOpen={() => setMobileNavOpen(true)} />
      <NavBar
        onMobileClose={() => setMobileNavOpen(false)}
        openMobile={isMobileNavOpen}
      />
      <div className={classes.wrapper}>
        <div className={classes.contentContainer}>
          <div className={classes.content}>{children}</div>
        </div>
      </div>
    </Root>
  );
}

DashboardLayout.propTypes = {
  children: PropTypes.any
};

export default DashboardLayout;
