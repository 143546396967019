/* eslint-disable no-use-before-define */
/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { useLocation, Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Box, Drawer, Hidden, List, ListSubheader } from '@mui/material';
import { PieChart as PieChartIcon, Users as UsersIcon } from 'react-feather';

import Logo from '../../../atoms/Logo';
import NavItem from './NavItem';

const PREFIX = 'NavBar';

const classes = {
  mobileDrawer: `${PREFIX}-mobileDrawer`,
  desktopDrawer: `${PREFIX}-desktopDrawer`,
  avatar: `${PREFIX}-avatar`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')(() => ({
  [`& .${classes.mobileDrawer}`]: {
    width: 256
  },

  [`& .${classes.desktopDrawer}`]: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)'
  },

  [`& .${classes.avatar}`]: {
    cursor: 'pointer',
    width: 64,
    height: 64
  }
}));

const navConfig = () => [
  {
    subheader: 'app',
    showSubHeader: false,
    items: [
      {
        title: 'Dashboard',
        icon: PieChartIcon,
        href: '/'
      },
      {
        title: 'Clients',
        icon: UsersIcon,
        href: '/clients'
      }
    ].filter(p => p)
  }
];

function renderNavItems({ items, ...rest }) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, ...rest }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({ acc, pathname, item, depth = 0 }) {
  if (!item) {
    return acc;
  }
  const key = item.title + depth;

  if (item.items) {
    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        key={key}
        info={item.info}
        open
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        key={key}
        info={item.info}
        title={item.title}
        activeIncludes={item.activeIncludes || null}
      />
    );
  }

  return acc;
}

function NavBar({ openMobile, onMobileClose }) {
  const location = useLocation();

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line
  }, [location.pathname]);

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <div style={{ overflowY: 'auto' }}>
        <Hidden lgUp>
          <Box p={2} display="flex" justifyContent="center">
            <RouterLink to="/">
              <Logo />
            </RouterLink>
          </Box>
        </Hidden>
        <Box p={2}>
          {navConfig().map(config => (
            <List
              key={config.subheader}
              subheader={
                config.showSubHeader && (
                  <ListSubheader disableGutters disableSticky>
                    {config.subheader}
                  </ListSubheader>
                )
              }
            >
              {renderNavItems({
                items: config.items,
                pathname: location.pathname
              })}
            </List>
          ))}
        </Box>
      </div>
    </Box>
  );

  return (
    <Root>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden lgDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </Root>
  );
}

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default NavBar;
