import React from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material';
import { Close } from '@mui/icons-material';

const Modal = ({ title, children, showCloseIcon, onClose, ...props }) => (
  <Dialog onClose={onClose} {...props}>
    {showCloseIcon && (
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: 'absolute',
          right: 24,
          top: 16,
          padding: 0,
          color: theme => theme.palette.grey[500]
        }}
      >
        <Close />
      </IconButton>
    )}
    {title && <DialogTitle>{title}</DialogTitle>}
    <DialogContent>{children}</DialogContent>
  </Dialog>
);

Modal.propTypes = {
  title: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  showCloseIcon: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired
};

export default Modal;
