import * as Sentry from '@sentry/react';

export const sendErrorToSentry = (errorMessage, userContextData, errorType) => {
  if (userContextData?.username) {
    Sentry.setUser({
      id: userContextData.username,
      email: userContextData.username
    });
  }

  Sentry.captureException(errorMessage, {
    tags: {
      type: errorType || 'unknown'
    }
  });
};
