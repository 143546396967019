import React, { useMemo, useState, useContext, useEffect } from 'react';
import {
  DialogActions,
  Stack,
  Autocomplete,
  TextField,
  Dialog,
  DialogContent,
  Button,
  DialogTitle,
  Typography,
  Select,
  MenuItem,
  Box,
  IconButton
} from '@mui/material';
import PropTypes from 'prop-types';
import { gql } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import UserContext from '../../../../context';
import { useAnalyticsFilters } from '../../../../hooks/analyticsFilters';
import { useAnalyticsHelpers } from '../../../../hooks/analyticsHelpers';
import { useCustomQuery } from '../../../../hooks/graphql';
import { Add, Remove } from '@mui/icons-material';
import { MdOutlineSubdirectoryArrowRight } from 'react-icons/md';

const GET_CUTTING_VALUES = gql`
  query CuttingValues(
    $clientId: ID!
    $channelsId: [String!]
    $from: String
    $to: String
    $timezone: String
    $conversionMethodology: String
  ) {
    datePickerFrom @client @export(as: "from")
    datePickerTo @client @export(as: "to")
    client(clientId: $clientId) {
      id
      attributionPingsSettings {
        cuttingValues(
          channelsId: $channelsId
          from: $from
          to: $to
          timezone: $timezone
          conversionMethodology: $conversionMethodology
        ) {
          id
          count
          channels {
            id
            count
            subChannels {
              id
              count
            }
          }
        }
      }
    }
  }
`;

const ChannelModal = ({
  handleClose,
  isOpen,
  viewFilters,
  selectedFilters,
  handleOnUpdate
}) => {
  const { t } = useTranslation();
  const { filters } = useAnalyticsFilters();
  const { findChannelName } = useAnalyticsHelpers();
  const [channelValues, setChannelValues] = useState([]);
  const [subChannelValues, setSubChannelValues] = useState([]);
  const { clientSettings } = useContext(UserContext);

  const [cuttingExactValues, setCuttingExactValues] = useState([]);
  const [cuttingFilterType, setCuttingFilterType] = useState('');

  const [containsType, setContainsType] = useState('or');
  const [containsValues, setContainsValues] = useState(['']);

  const { data: dataCutting } = useCustomQuery(GET_CUTTING_VALUES, {
    variables: {
      clientId: clientSettings.id,
      timezone: clientSettings.timezone,
      channelsId: channelValues?.map(({ id }) => id)
    },
    skip: channelValues?.length === 0 || cuttingFilterType !== 'exact',
    fetchPolicy: 'no-cache',
    showLoader: false
  });

  useEffect(() => {
    if (isOpen) {
      setChannelValues(
        selectedFilters
          ?.find(({ type }) => type === 'channel')
          ?.ids?.map(id => ({
            id,
            name: findChannelName(id)
          })) || []
      );

      setSubChannelValues(
        selectedFilters
          ?.find(({ type }) => type === 'subChannel')
          ?.ids?.map(id => ({
            id,
            name: findChannelName(id)
          })) || []
      );

      setCuttingExactValues(
        selectedFilters?.find(({ type }) => type === 'cutting')?.ids || []
      );

      if (selectedFilters?.some(({ type }) => type === 'cuttingContainsOr')) {
        setContainsValues(
          selectedFilters?.find(({ type }) => type === 'cuttingContainsOr')
            ?.ids || []
        );
        setContainsType('or');
        setCuttingFilterType('contains');
      }

      if (selectedFilters?.some(({ type }) => type === 'cuttingContainsAnd')) {
        setContainsValues(
          selectedFilters?.find(({ type }) => type === 'cuttingContainsAnd')
            ?.ids || []
        );
        setContainsType('and');
        setCuttingFilterType('contains');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(filters), isOpen]);

  const channelsList = useMemo(() => {
    return clientSettings?.attributionPingsSettings?.channels
      ?.filter(
        ({ id }) =>
          !viewFilters?.some(({ type }) => type === 'channel') ||
          viewFilters?.some(
            ({ type, ids }) => type === 'channel' && ids.includes(id)
          )
      )
      ?.sort((a, b) => a.name.localeCompare(b.name))
      ?.map(channel => ({ id: channel.id, name: channel.name }));
  }, [viewFilters, clientSettings]);

  const cuttingList = useMemo(() => {
    return dataCutting?.client?.attributionPingsSettings?.cuttingValues
      ?.sort((a, b) => a.id.localeCompare(b.id))
      ?.filter(
        ({ id }) =>
          !viewFilters?.some(({ type }) => type === 'cutting') ||
          viewFilters?.some(
            ({ type, ids }) => type === 'cutting' && ids.includes(id)
          )
      )
      .map(({ id }) => id);
  }, [viewFilters, dataCutting]);

  const subChannelsList = useMemo(() => {
    if (!channelValues) {
      return null;
    }

    return channelValues
      .map(channelValue => {
        if (
          clientSettings.attributionPingsSettings.channels.find(
            ({ id }) => id === channelValue.id
          )?.subChannels?.length > 0
        ) {
          return clientSettings.attributionPingsSettings.channels.find(
            ({ id }) => id === channelValue.id
          ).subChannels;
        }
        return null;
      })
      .filter(p => p)
      .flat()
      .filter(
        ({ id }) =>
          !viewFilters?.some(({ type }) => type === 'subChannel') ||
          viewFilters?.some(
            ({ type, ids }) => type === 'subChannel' && ids.includes(id)
          )
      );
  }, [viewFilters, channelValues, clientSettings]);

  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      onClose={() => {
        setCuttingFilterType('');
        handleClose();
      }}
      open={isOpen}
    >
      <DialogTitle>{t('ma.filter.filterByChannel')}</DialogTitle>

      <DialogContent>
        <Stack spacing={2}>
          <Autocomplete
            isOptionEqualToValue={(option, value) => option.id === value.id}
            multiple
            value={channelValues}
            onChange={(_, v) => setChannelValues(v)}
            options={channelsList}
            getOptionLabel={option => option.name}
            renderInput={params => (
              <TextField
                {...params}
                variant="standard"
                label=""
                placeholder={t('ma.filter.selectChannel')}
              />
            )}
          />
          {subChannelsList?.length > 0 && (
            <>
              <Typography variant="subtitle2">Sous-leviers</Typography>
              <Autocomplete
                isOptionEqualToValue={(option, value) => option.id === value.id}
                multiple
                value={subChannelValues?.sort((a, b) =>
                  a.name.localeCompare(b.name)
                )}
                onChange={(_, v) => setSubChannelValues(v)}
                options={subChannelsList}
                getOptionLabel={option => option.name}
                renderInput={params => (
                  <TextField
                    {...params}
                    variant="standard"
                    label=""
                    placeholder={t('ma.filter.selectSubChannel')}
                  />
                )}
              />
            </>
          )}
          {channelValues?.length > 0 && (
            <>
              <Typography variant="subtitle2">Détails</Typography>

              <Select
                variant="standard"
                label={t('ma.filter.targets.filterType')}
                labelId="ty-select-target-filter-type"
                id="ty-select-target-filter-type"
                value={cuttingFilterType}
                onChange={e => {
                  setCuttingExactValues([]);
                  setContainsValues(['']);
                  setCuttingFilterType(e.target.value);
                }}
                displayEmpty
              >
                <MenuItem disabled value="">
                  <span style={{ color: '#657c87' }}>
                    {t('ma.filter.targets.filterType')}
                  </span>
                </MenuItem>
                <MenuItem value="exact">
                  {t('ma.filter.targets.exact')}
                </MenuItem>
                <MenuItem value="contains">
                  {t('ma.filter.targets.contains')}
                </MenuItem>
              </Select>

              {cuttingFilterType === 'exact' &&
                dataCutting?.client?.attributionPingsSettings?.cuttingValues
                  ?.length > 0 && (
                  <Stack direction="row" alignItems="center" spacing={1}>
                    <MdOutlineSubdirectoryArrowRight
                      style={{
                        transform: 'translateY(2px)'
                      }}
                    />
                    <Autocomplete
                      multiple
                      fullWidth
                      options={cuttingList}
                      onChange={(_, v) => setCuttingExactValues(v)}
                      value={cuttingExactValues}
                      getOptionLabel={option => option}
                      renderInput={params => (
                        <TextField
                          {...params}
                          variant="standard"
                          label=""
                          placeholder={t('ma.filter.selectDetails')}
                        />
                      )}
                    />
                  </Stack>
                )}

              {cuttingFilterType === 'contains' && (
                <>
                  {containsValues.map((item, index) => {
                    return (
                      <Stack
                        key={index}
                        direction="row"
                        spacing={2}
                        alignItems="center"
                      >
                        <MdOutlineSubdirectoryArrowRight
                          style={{
                            transform: 'translateY(2px)',
                            opacity: index === 0 ? 1 : 0
                          }}
                        />
                        {index > 0 && (
                          <Select
                            variant="standard"
                            value={containsType}
                            onChange={e => {
                              setContainsType(e.target.value);
                            }}
                          >
                            <MenuItem value="and">{t('commun.and')}</MenuItem>
                            <MenuItem value="or">{t('commun.or')}</MenuItem>
                          </Select>
                        )}
                        <TextField
                          fullWidth
                          onChange={e => {
                            setContainsValues(
                              containsValues.map((i, ind) =>
                                ind === index ? e.target.value : i
                              )
                            );
                          }}
                          placeholder={t('ma.filter.targets.term')}
                          value={item}
                          variant="standard"
                        />
                        {index === containsValues.length - 1 && (
                          <Box>
                            <IconButton
                              size="small"
                              onClick={() =>
                                setContainsValues([...containsValues, ''])
                              }
                            >
                              <Add />
                            </IconButton>
                          </Box>
                        )}

                        <Box>
                          <IconButton
                            disabled={containsValues.length === 1}
                            size="small"
                            onClick={() =>
                              setContainsValues(
                                containsValues.filter((_, i) => i !== index)
                              )
                            }
                          >
                            <Remove />
                          </IconButton>
                        </Box>
                      </Stack>
                    );
                  })}
                </>
              )}
            </>
          )}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setCuttingFilterType('');
            handleClose();
          }}
          color="secondary"
        >
          {t('commun.cancel')}
        </Button>
        <Button
          disabled={channelValues?.length <= 0}
          onClick={() => {
            handleOnUpdate(
              channelValues,
              subChannelValues,
              cuttingExactValues,
              containsValues,
              cuttingFilterType,
              containsType
            );
            handleClose();
          }}
          color="primary"
        >
          {t('commun.confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ChannelModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  handleOnUpdate: PropTypes.func.isRequired,
  viewFilters: PropTypes.array.isRequired,
  selectedFilters: PropTypes.array.isRequired
};

export default ChannelModal;
