/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  dev: {
    aws_project_region: 'eu-west-1',
    aws_cognito_identity_pool_id:
      'eu-west-1:afcbbf3c-8547-47e0-a4f3-037ceb6c3352',
    aws_cognito_region: 'eu-west-1',
    aws_user_pools_id: 'eu-west-1_nJnMnoIix',
    aws_user_pools_web_client_id: '630jlvj8neu5n81i7dniikug9n',
    oauth: {},
    aws_cognito_username_attributes: [],
    aws_cognito_social_providers: [],
    aws_cognito_signup_attributes: ['EMAIL'],
    aws_cognito_mfa_configuration: 'OFF',
    aws_cognito_mfa_types: ['SMS'],
    aws_cognito_password_protection_settings: {
      passwordPolicyMinLength: 8,
      passwordPolicyCharacters: []
    },
    aws_cognito_verification_mechanisms: ['EMAIL'],
    aws_content_delivery_bucket:
      'thankyou-app-20200412181333-hostingbucket-dev',
    aws_content_delivery_bucket_region: 'eu-west-1',
    aws_content_delivery_url:
      'http://thankyou-app-20200412181333-hostingbucket-dev.s3-website-eu-west-1.amazonaws.com',
    aws_user_files_s3_bucket: 'closingdev-dev',
    aws_user_files_s3_bucket_region: 'eu-west-1'
  },
  prod: {
    aws_project_region: 'eu-west-1',
    aws_cognito_identity_pool_id:
      'eu-west-1:aa5151c9-a17f-4702-b268-bace6ab81473',
    aws_cognito_region: 'eu-west-1',
    aws_user_pools_id: 'eu-west-1_RD3N9hLFt',
    aws_user_pools_web_client_id: '43l2cv07e8i55uu026uno8iip0',
    oauth: {},
    aws_cognito_username_attributes: [],
    aws_cognito_social_providers: [],
    aws_cognito_signup_attributes: ['EMAIL'],
    aws_cognito_mfa_configuration: 'OFF',
    aws_cognito_mfa_types: ['SMS'],
    aws_cognito_password_protection_settings: {
      passwordPolicyMinLength: 8,
      passwordPolicyCharacters: []
    },
    aws_cognito_verification_mechanisms: ['EMAIL'],
    aws_content_delivery_bucket:
      'thankyou-app-20200412181333-hostingbucket-prod',
    aws_content_delivery_bucket_region: 'eu-west-1',
    aws_content_delivery_url:
      'http://thankyou-app-20200412181333-hostingbucket-prod.s3-website-eu-west-1.amazonaws.com',
    aws_user_files_s3_bucket: 'closingprod-prod',
    aws_user_files_s3_bucket_region: 'eu-west-1'
  }
};

export default awsmobile;
