import React, { useState, useEffect, useMemo } from 'react';
import {
  DialogActions,
  Dialog,
  DialogContent,
  Button,
  Autocomplete,
  DialogTitle,
  TextField
} from '@mui/material';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { useAnalyticsFilters } from '../../../../hooks/analyticsFilters';

const ConversionTypeModal = ({
  handleClose,
  isOpen,
  viewFilters,
  selectedFilters,
  handleOnUpdate
}) => {
  const { t } = useTranslation();
  const [values, setValues] = useState([]);
  const { filters } = useAnalyticsFilters();

  useEffect(() => {
    if (isOpen) {
      setValues(
        selectedFilters?.find(({ type }) => type === 'conversion_type')?.ids ||
          []
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(filters), isOpen, selectedFilters]);

  const optionsList = useMemo(() => {
    return ['ropo', 'online']?.filter(
      id =>
        !viewFilters?.some(({ type }) => type === 'conversion_type') ||
        viewFilters?.some(
          ({ type, ids }) => type === 'conversion_type' && ids.includes(id)
        )
    );
  }, [viewFilters]);

  return (
    <Dialog maxWidth="sm" fullWidth onClose={handleClose} open={isOpen}>
      <DialogTitle>{t('ma.omnicanal.conversionTypes')}</DialogTitle>

      <DialogContent>
        <Autocomplete
          multiple
          value={values}
          onChange={(_, v) => setValues(v)}
          id="tags-standard"
          options={optionsList}
          getOptionLabel={option => t(`ma.omnicanal.${option}`)}
          renderInput={params => (
            <TextField
              {...params}
              variant="standard"
              label=""
              placeholder={t('ma.omnicanal.conversionTypes')}
            />
          )}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          {t('commun.cancel')}
        </Button>
        <Button
          disabled={values?.length <= 0}
          onClick={() => {
            handleOnUpdate(values);
            handleClose();
          }}
          color="primary"
        >
          {t('commun.confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ConversionTypeModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  handleOnUpdate: PropTypes.func.isRequired,
  viewFilters: PropTypes.array.isRequired,
  selectedFilters: PropTypes.array.isRequired
};

export default ConversionTypeModal;
