import React from 'react';
import { styled } from '@mui/material/styles';
import { Typography, Button, Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const PREFIX = 'NotFound';

const classes = {
  title: `${PREFIX}-title`
};

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`& .${classes.title}`]: {
    color: theme.palette.primary.main,
    fontSize: '52px'
  }
}));

const NotFound = () => {
  let navigate = useNavigate();

  return (
    <StyledGrid
      style={{ height: '100vh', width: '100vw' }}
      container
      justifyContent="center"
      alignItems="center"
      direction="column"
      spacing={2}
    >
      <Grid item>
        <Typography className={classes.title} variant="h1">
          La page n&apos;existe pas
        </Typography>
      </Grid>
      <Grid item>
        <Button variant="outlined" onClick={() => navigate('/')}>
          Retour à la page d&apos;accueil
        </Button>
      </Grid>
    </StyledGrid>
  );
};

export default NotFound;
