export const rbac = {
  // General
  deleteUserToClient: ['appAdmin', 'clientAdmin'],
  addUserToClient: ['appAdmin', 'clientAdmin'],
  updateUserToClient: ['appAdmin', 'clientAdmin'],
  accessAdminLayout: ['appAdmin'],

  // Brand Safety
  brandSafetyRunManualAnalysis: ['appAdmin'],
  accessBrandSafetySettings: ['clientAdmin'],
  brandSafetyArchiveWebsite: ['clientAdmin'],
  accessReadOnlyBrandSafetySettings: ['clientMember'],

  // Marketing Analytics
  seeExportFromAnyUsers: ['clientAdmin'],
  deleteAnyExport: ['clientAdmin'],
  accessMarketingAnalyticsSettings: ['clientAdmin'],
  accessReadOnlyMarketingAnalyticsSettings: ['clientMember'],
  accessMarketingAnalyticsSettingsIfActiveConnector: [
    'clientMember',
    'clientPartner'
  ],

  // Affiliate Marketing
  engagementRateOptionShow: ['appAdmin'],
  accessAffiliateSettings: ['clientAdmin'],
  accessReadOnlyAffiliateSettings: ['clientMember'],
  updateBannedFrauds: ['clientAdmin'],
  updateBannedAffiliate: ['clientAdmin'],
  updateRemCatAffiliate: ['clientAdmin'],
  updateCatAffiliate: ['clientAdmin'],
  updateNameAffiliate: ['clientAdmin'],
  updateSimulationRemModels: ['clientAdmin'],
  updateOwnSimulationRemModels: ['clientMember'],
  deleteSimulationRemModels: ['clientAdmin'],
  deleteOwnSimulationRemModels: ['clientMember'],
  addSimulationRemModel: ['clientAdmin', 'clientMember'],
  addSimulation: ['clientAdmin', 'clientMember'],
  deleteSimulation: ['clientAdmin'],
  deleteOwnSimulation: ['clientMember'],
  addClosing: ['clientAdmin', 'clientMember'],
  deleteClosing: ['clientAdmin'],
  deleteOwnClosing: ['clientMember'],
  validateClosing: ['clientAdmin'],
  validateOwnClosing: ['clientMember'],
  updateClosingConversionStatus: ['clientAdmin'],
  updateOwnClosingConversionStatus: ['clientMember'],
  validateEprivacyReport: ['clientAdmin'],
  updateEprivacyReportAffiliateStatus: ['clientAdmin'],
  updateEprivacyReportCancelationRate: ['clientAdmin'],
  addRecruitmentKeywordsList: ['clientAdmin', 'clientMember'],
  deleteRecruitmentKeywordsList: ['clientAdmin'],
  updateRecruitmentWebsiteCategory: ['clientAdmin']
};
