import React from 'react';
import { styled } from '@mui/material/styles';
import { SnackbarProvider } from 'notistack';
import PropTypes from 'prop-types';

import { themeColors } from './theme';

const StyledSnackbarProvider = styled(SnackbarProvider)`
  &.SnackbarItem-variantSuccess {
    background-color: ${themeColors.green};
  }
  &.SnackbarItem-variantError {
    background-color: ${themeColors.red};
  }
  &.SnackbarItem-variantWarning {
    background-color: ${themeColors.orange};
  }
`;

const Snackbar = ({ children }) => (
  <StyledSnackbarProvider autoHideDuration={3000} maxSnack={1}>
    {children}
  </StyledSnackbarProvider>
);

Snackbar.propTypes = {
  children: PropTypes.node.isRequired
};

export default Snackbar;
