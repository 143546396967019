import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import { NavLink as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Button, Collapse, ListItem } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

const PREFIX = 'NavItem';

const classes = {
  item: `${PREFIX}-item`,
  itemLeaf: `${PREFIX}-itemLeaf`,
  button: `${PREFIX}-button`,
  buttonLeaf: `${PREFIX}-buttonLeaf`,
  titleBold: `${PREFIX}-titleBold`,
  icon: `${PREFIX}-icon`,
  title: `${PREFIX}-title`,
  active: `${PREFIX}-active`,
  subItemsContainer: `${PREFIX}-subItemsContainer`
};
const StyledListItem = styled(ListItem)(({ theme }) => ({
  [`&.${classes.item}`]: {
    display: 'block',
    paddingTop: 0,
    paddingBottom: 0
  },

  [`&.${classes.itemLeaf}`]: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0
  },

  [`& .${classes.button}`]: {
    color: theme.palette.text.secondary,
    padding: '10px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%'
  },

  [`& .${classes.buttonLeaf}`]: {
    color: theme.palette.text.secondary,
    padding: '5px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    fontWeight: theme.typography.fontWeightRegular
  },

  [`& .${classes.icon}`]: {
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1)
  },

  [`& .${classes.title}`]: {
    marginRight: 'auto',
    textTransform: 'none'
  },

  [`& .${classes.titleBold}`]: {
    marginRight: 'auto',
    textTransform: 'none',
    fontWeight: theme.typography.fontWeightMedium
  },

  [`& .${classes.active}`]: {
    color: theme.palette.primary.main,
    '& $title': {
      fontWeight: theme.typography.fontWeightMedium
    },
    '& $icon': {
      color: theme.palette.primary.main
    }
  },

  [`& .${classes.subItemsContainer}`]: {
    marginBottom: theme.spacing(1)
  }
}));

const queryString = window.location.pathname;

function NavItem({
  title,
  href,
  depth,
  children,
  icon: Icon,

  open: openProp,
  info: Info,
  activeIncludes,
  ...rest
}) {
  const [open, setOpen] = useState(openProp);

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  let paddingLeft = 8;

  if (depth > 0) {
    paddingLeft = 32 + 8 * depth;
  }

  const style = { paddingLeft };

  if (children) {
    if (!href) {
      return (
        <StyledListItem
          className={classes.item}
          disableGutters
          key={title}
          {...rest}
        >
          <Button
            className={classes.button}
            onClick={handleToggle}
            style={style}
          >
            {Icon && <Icon className={classes.icon} size="20" />}
            <span className={depth === 0 ? classes.titleBold : classes.title}>
              {title}
            </span>
            {open ? (
              <ExpandLessIcon size="small" color="inherit" />
            ) : (
              <ExpandMoreIcon size="small" color="inherit" />
            )}
          </Button>
          <Collapse className={classes.subItemsContainer} in={open}>
            {children}
          </Collapse>
        </StyledListItem>
      );
    }

    return (
      <StyledListItem
        className={classes.item}
        disableGutters
        key={title}
        {...rest}
      >
        <Button
          activeClassName={classes.active}
          className={classes.buttonLeaft}
          component={RouterLink}
          exact={!activeIncludes || !queryString.includes(activeIncludes)}
          style={style}
          to={href}
        >
          {Icon && <Icon className={classes.icon} size="20" />}
          <span className={depth === 0 ? classes.titleBold : classes.title}>
            {title}
          </span>
        </Button>

        {children}
      </StyledListItem>
    );
  }

  return (
    <StyledListItem
      className={classes.itemLeaf}
      disableGutters
      key={title}
      {...rest}
    >
      <Button
        activeClassName={classes.active}
        className={classes.buttonLeaf}
        component={RouterLink}
        exact={!activeIncludes || !queryString.includes(activeIncludes)}
        style={style}
        to={href}
      >
        {Icon && <Icon className={classes.icon} size="20" />}
        <span className={depth === 0 ? classes.titleBold : classes.title}>
          {title}
        </span>
        {Info && <Info className={classes.info} />}
      </Button>
    </StyledListItem>
  );
}

NavItem.propTypes = {
  activeIncludes: PropTypes.string,
  children: PropTypes.node,
  depth: PropTypes.number.isRequired,
  href: PropTypes.string,
  icon: PropTypes.any,
  info: PropTypes.any,
  open: PropTypes.bool,
  title: PropTypes.string.isRequired
};

NavItem.defaultProps = {
  open: false,
  activeIncludes: null
};

export default NavItem;
