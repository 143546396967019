import i18n, { use } from 'i18next';
import { initReactI18next } from 'react-i18next';

import enTranslation from './translations/en.json';
import frTranslation from './translations/fr.json';

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  en: {
    translation: enTranslation
  },
  fr: {
    translation: frTranslation
  }
};

use(initReactI18next) // passes i18n down to react-i18next
  .init({
    debug: process.env.NODE_ENV !== 'production',
    resources,
    lng: window?.navigator?.language?.slice(0, 2) === 'fr' ? 'fr' : 'en',
    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;
