import React, { useState, useEffect, useContext, useMemo } from 'react';
import {
  DialogActions,
  Dialog,
  DialogContent,
  Button,
  DialogTitle,
  TextField,
  Typography,
  FormControl,
  Select,
  MenuItem,
  Grid
} from '@mui/material';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { useAnalyticsFilters } from '../../../../hooks/analyticsFilters';
import UserContext from '../../../../context';

const CustomAttributeNumericModal = ({
  id,
  handleClose,
  isOpen,
  viewFilters,
  selectedFilters,
  handleOnUpdate
}) => {
  const { t } = useTranslation();
  const [numericRelation, setNumericRelation] = useState('greaterThan');
  const [numericValue, setNumericValue] = useState('');
  const { filters } = useAnalyticsFilters();
  const { clientSettings } = useContext(UserContext);

  const customAttributeData = useMemo(
    () =>
      clientSettings.attributionPingsSettings.customAttributes.find(
        ca => ca.internalAttributKey === id
      ),
    [clientSettings.attributionPingsSettings.customAttributes, id]
  );

  const attributeFilterId = useMemo(
    () => `customAttribute_${customAttributeData.type}_${id}`,
    [customAttributeData, id]
  );

  useEffect(() => {
    if (isOpen) {
      const filterData = selectedFilters?.find(
        ({ type }) =>
          type ===
          `${
            customAttributeData.dimension === 'conversion'
              ? 'customAttribute'
              : 'audienceDimension'
          }_${customAttributeData.type}_${id}`
      );

      setNumericValue(filterData?.ids?.[0]?.split('_')?.[0] || '');
      setNumericRelation(
        filterData?.ids?.[0]?.split('_')?.[1] || 'greaterThan'
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(filters), isOpen, selectedFilters]);

  return (
    <Dialog maxWidth="sm" fullWidth onClose={handleClose} open={isOpen}>
      <DialogTitle>
        {t(
          `ma.filter.filterBy${
            customAttributeData.dimension === 'conversion'
              ? 'CustomAttribute'
              : 'AudienceDimension'
          }`,
          {
            name: customAttributeData.name
          }
        )}
      </DialogTitle>

      <DialogContent>
        <Grid container alignItems="center" spacing={2}>
          <Grid item>
            <Typography>{customAttributeData.name}</Typography>
          </Grid>
          <Grid item>
            <FormControl style={{ minWidth: '100px' }}>
              <Select
                labelId="granularity"
                id="granularity"
                value={numericRelation}
                onChange={e => setNumericRelation(e.target.value)}
                variant="standard"
                disabled={
                  !!viewFilters?.some(({ type }) => type === attributeFilterId)
                }
              >
                <MenuItem value="greaterThan">&gt;=</MenuItem>
                <MenuItem value="lowerThan">&lt;=</MenuItem>
                <MenuItem value="equal">=</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item>
            <TextField
              onChange={e => setNumericValue(e.target.value)}
              placeholder={t('commun.value')}
              value={numericValue}
              variant="standard"
              type="number"
              disabled={
                !!viewFilters?.some(({ type }) => type === attributeFilterId)
              }
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          {t('commun.cancel')}
        </Button>
        <Button
          disabled={numericValue === ''}
          onClick={() => {
            handleOnUpdate(
              `${
                customAttributeData.dimension === 'conversion'
                  ? 'customAttribute'
                  : 'audienceDimension'
              }_${customAttributeData.type}_${id}`,
              [`${numericValue}_${numericRelation}`]
            );
            handleClose();
          }}
          color="primary"
        >
          {t('commun.confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

CustomAttributeNumericModal.propTypes = {
  id: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  handleOnUpdate: PropTypes.func.isRequired,
  viewFilters: PropTypes.array.isRequired,
  selectedFilters: PropTypes.array.isRequired
};

export default CustomAttributeNumericModal;
