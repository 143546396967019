import { Box, Button, Card, Stack, Typography } from '@mui/material';
import { Auth } from 'aws-amplify';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { TextField } from 'formik-mui';
import React, { useEffect, useState } from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import ContainerPage from '../layouts/ContainerPage';
import Logo from '../atoms/Logo';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

const LoginPage = () => {
  const { t, i18n } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [errorMessage, setErrorMessage] = useState(null);
  const [stateToShow, setStateToShow] = useState('signin');
  const [signedInUser, setSignedInUser] = useState(null);

  useEffect(() => {
    setErrorMessage(null);
  }, [stateToShow]);

  return (
    <ContainerPage title={t('loginPage.pageTitle')} maxWidth={false}>
      <Stack
        spacing={3}
        alignItems="center"
        sx={{
          width: '100%',
          maxWidth: '400px',
          position: 'absolute',
          left: 0,
          right: 0,
          bottom: 0,
          margin: '0 auto',
          top: '30%'
        }}
      >
        <Logo />
        <Card sx={{ width: '100%' }}>
          <Box p={3}>
            <Stack spacing={0}>
              {stateToShow !== 'signin' && (
                <Box>
                  <Button
                    onClick={() => setStateToShow('signin')}
                    variant="Text"
                    sx={{ paddingLeft: 0 }}
                  >
                    <Stack spacing={1} direction="row" alignItems="center">
                      <ArrowBackIcon
                        fontSize="small"
                        sx={{ color: '#546e7a' }}
                      />
                      <Typography variant="caption" color="textSecondary">
                        {t('loginPage.backToSignInPage')}
                      </Typography>
                    </Stack>
                  </Button>
                </Box>
              )}
              {stateToShow === 'forgotPasswordSendCode' && (
                <>
                  <Typography variant="h5" mb={3}>
                    {t('loginPage.sendCode.title')}
                  </Typography>
                  <Formik
                    enableReinitialize
                    validationSchema={Yup.object().shape({
                      username: Yup.string()
                        .email(t('commun.form.requiredEmail'))
                        .required(t('commun.form.requiredField'))
                    })}
                    initialValues={{
                      username: ''
                    }}
                    onSubmit={async (values, { setSubmitting }) => {
                      try {
                        await Auth.forgotPassword(values.username);
                        setStateToShow('forgotPasswordEnterCode');

                        enqueueSnackbar(
                          t('loginPage.sendCode.successMessage'),
                          {
                            variant: 'success'
                          }
                        );
                      } catch (err) {
                        setErrorMessage(err.message);
                      }

                      setSubmitting(false);
                    }}
                  >
                    {({ isSubmitting, handleSubmit }) => (
                      <Form>
                        <Stack spacing={2}>
                          <Field
                            component={TextField}
                            type="string"
                            variant="standard"
                            size="small"
                            fullWidth
                            name="username"
                            label={t('commun.email')}
                          />
                          {errorMessage && (
                            <Typography
                              textAlign="center"
                              color="error"
                              fontSize="12px"
                            >
                              {i18n.exists(
                                `loginPage.errorMessages.${errorMessage}`
                              )
                                ? t(`loginPage.errorMessages.${errorMessage}`)
                                : errorMessage}
                            </Typography>
                          )}
                          <Button
                            variant="contained"
                            color="primary"
                            disabled={isSubmitting}
                            onClick={handleSubmit}
                            type="submit"
                          >
                            {t('loginPage.sendCode.validBtnLabel')}
                          </Button>
                        </Stack>
                      </Form>
                    )}
                  </Formik>
                </>
              )}
              {stateToShow === 'forgotPasswordEnterCode' && (
                <>
                  <Typography variant="h5" mb={3}>
                    {t('loginPage.enterCode.title')}
                  </Typography>
                  <Formik
                    enableReinitialize
                    validationSchema={Yup.object().shape({
                      username: Yup.string()
                        .email(t('commun.form.requiredEmail'))
                        .required(t('commun.form.requiredField')),
                      newPassword: Yup.string().required(
                        t('commun.form.requiredField')
                      ),
                      code: Yup.string().required(
                        t('commun.form.requiredField')
                      )
                    })}
                    initialValues={{
                      username: '',
                      newPassword: '',
                      code: ''
                    }}
                    onSubmit={async (values, { setSubmitting }) => {
                      try {
                        await Auth.forgotPasswordSubmit(
                          values.username,
                          values.code,
                          values.newPassword
                        );

                        enqueueSnackbar(
                          t('loginPage.enterCode.successMessage'),
                          {
                            variant: 'success'
                          }
                        );

                        setStateToShow('signin');
                      } catch (err) {
                        setErrorMessage(err.message);
                      }

                      setSubmitting(false);
                    }}
                  >
                    {({ resetForm, isSubmitting, handleSubmit }) => (
                      <Form>
                        <Stack spacing={2}>
                          <Field
                            component={TextField}
                            type="string"
                            variant="standard"
                            size="small"
                            fullWidth
                            name="code"
                            label="Code"
                          />
                          <Field
                            component={TextField}
                            type="string"
                            variant="standard"
                            size="small"
                            fullWidth
                            name="username"
                            label={t('commun.email')}
                          />
                          <Field
                            component={TextField}
                            type="password"
                            variant="standard"
                            size="small"
                            fullWidth
                            name="newPassword"
                            label={t('account.newPassword')}
                          />
                          {errorMessage && (
                            <Typography
                              textAlign="center"
                              color="error"
                              fontSize="12px"
                            >
                              {i18n.exists(
                                `loginPage.errorMessages.${errorMessage}`
                              )
                                ? t(`loginPage.errorMessages.${errorMessage}`)
                                : errorMessage}
                            </Typography>
                          )}
                          <Button
                            variant="contained"
                            color="primary"
                            disabled={isSubmitting}
                            onClick={handleSubmit}
                            type="submit"
                          >
                            {t('loginPage.enterCode.validBtnLabel')}
                          </Button>
                          <Button
                            variant="text"
                            color="secondary"
                            disabled={isSubmitting}
                            onClick={() =>
                              setStateToShow('forgotPasswordSendCode')
                            }
                          >
                            {t('loginPage.enterCode.sendNewCodeBtnLabel')}
                          </Button>
                        </Stack>
                      </Form>
                    )}
                  </Formik>
                </>
              )}
              {stateToShow === 'signin' && (
                <>
                  <Formik
                    enableReinitialize
                    validationSchema={Yup.object().shape({
                      username: Yup.string()
                        .email(t('commun.form.requiredEmail'))
                        .required(t('commun.form.requiredField')),
                      password: Yup.string().required(
                        t('commun.form.requiredField')
                      )
                    })}
                    initialValues={{
                      username: '',
                      password: '',
                      fax_number: ''
                    }}
                    onSubmit={async (values, { setSubmitting }) => {
                      if (values.fax_number !== '') {
                        return null;
                      }

                      let payload;
                      try {
                        try {
                          payload = await Auth.signIn(
                            values.username,
                            values.password
                          );
                        } catch (err) {
                          if (err.message.includes('User does not exist')) {
                            payload = await Auth.signIn(
                              values.username.toLowerCase(),
                              values.password
                            );
                          } else {
                            throw err;
                          }
                        }

                        if (payload.challengeName !== 'NEW_PASSWORD_REQUIRED') {
                          enqueueSnackbar(
                            t('loginPage.signIn.successMessage'),
                            {
                              variant: 'success'
                            }
                          );
                        } else {
                          setSignedInUser(payload);
                          setStateToShow('completeNewPassword');
                        }
                      } catch (err) {
                        setErrorMessage(err.message);
                      }

                      setSubmitting(false);
                    }}
                  >
                    {({ isSubmitting, handleSubmit }) => (
                      <Form>
                        <Stack spacing={2}>
                          <Field
                            component={TextField}
                            type="string"
                            variant="standard"
                            size="small"
                            fullWidth
                            name="fax_number"
                            sx={{ display: 'none' }}
                          />
                          <Field
                            component={TextField}
                            type="string"
                            variant="standard"
                            size="small"
                            fullWidth
                            name="username"
                            label={t('commun.email')}
                          />
                          <Field
                            component={TextField}
                            type="password"
                            variant="standard"
                            size="small"
                            fullWidth
                            name="password"
                            label={t('commun.password')}
                          />

                          {errorMessage && (
                            <Typography
                              textAlign="center"
                              color="error"
                              fontSize="12px"
                            >
                              {i18n.exists(
                                `loginPage.errorMessages.${errorMessage}`
                              )
                                ? t(`loginPage.errorMessages.${errorMessage}`)
                                : errorMessage}
                            </Typography>
                          )}

                          <Button
                            variant="contained"
                            color="primary"
                            disabled={isSubmitting}
                            type="submit"
                            onClick={handleSubmit}
                          >
                            {t('loginPage.signIn.title')}
                          </Button>
                          <Button
                            variant="text"
                            color="secondary"
                            disabled={isSubmitting}
                            onClick={() =>
                              setStateToShow('forgotPasswordSendCode')
                            }
                          >
                            {t('loginPage.signIn.forgotPasswordBtnLabel')}
                          </Button>
                        </Stack>
                      </Form>
                    )}
                  </Formik>
                </>
              )}
              {stateToShow.includes('completeNewPassword') && (
                <>
                  <Typography variant="h5" mb={3}>
                    {t('loginPage.createNewPassword.title')}
                  </Typography>
                  <Formik
                    enableReinitialize
                    validationSchema={Yup.object().shape({
                      newPassword: Yup.string().required(
                        t('commun.form.requiredField')
                      )
                    })}
                    initialValues={{
                      newPassword: ''
                    }}
                    onSubmit={async (values, { setSubmitting }) => {
                      try {
                        await Auth.completeNewPassword(
                          signedInUser,
                          values.newPassword
                        );
                        enqueueSnackbar(t('loginPage.signIn.successMessage'), {
                          variant: 'success'
                        });
                      } catch (err) {
                        setErrorMessage(err.message);
                      }

                      setSubmitting(false);
                    }}
                  >
                    {({ isSubmitting, handleSubmit }) => (
                      <Form>
                        <Stack spacing={2}>
                          <Field
                            component={TextField}
                            type="password"
                            variant="standard"
                            size="small"
                            fullWidth
                            name="newPassword"
                            label={t('commun.password')}
                          />

                          {errorMessage && (
                            <Typography
                              textAlign="center"
                              color="error"
                              fontSize="12px"
                            >
                              {i18n.exists(
                                `loginPage.errorMessages.${errorMessage}`
                              )
                                ? t(`loginPage.errorMessages.${errorMessage}`)
                                : errorMessage}
                            </Typography>
                          )}

                          <Button
                            variant="contained"
                            color="primary"
                            disabled={isSubmitting}
                            onClick={handleSubmit}
                            type="submit"
                          >
                            {t('loginPage.signIn.title')}
                          </Button>
                        </Stack>
                      </Form>
                    )}
                  </Formik>
                </>
              )}
            </Stack>
          </Box>
        </Card>
      </Stack>
    </ContainerPage>
  );
};

export default LoginPage;
