import React from 'react';
import PropTypes from 'prop-types';
import { Container, styled } from '@mui/material';

import Page from './Page';

const StyledPage = styled(Page)(({ theme }) => ({
  backgroundColor: theme.palette.background.dark,
  minHeight: '100%',
  paddingTop: theme.spacing(3),
  paddingBottom: theme.spacing(3)
}));

const ContainerPage = ({ children, title, maxWidth }) => (
  <StyledPage title={title}>
    <Container maxWidth={maxWidth}>{children}</Container>
  </StyledPage>
);

ContainerPage.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  maxWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired
};

export default ContainerPage;
