import React from 'react';
import * as Sentry from '@sentry/react';
import PropTypes from 'prop-types';

import UserContext from './context';
import ErrorSentry from './pages/ErrorSentry';

class SentryComponent extends React.Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);
    this.state = {};
  }

  static getDerivedStateFromError(error) {
    console.log(`error detected: error name ${error.name}`);
    return { hasError: true, errorName: error.name };
  }

  componentDidCatch(error, errorInfo) {
    if (error.name !== 'ChunkLoadError' && error.name !== 'BlockedError') {
      Sentry.withScope(scope => {
        scope.setExtras(errorInfo);
        Sentry.captureException(error, {
          tags: {
            email: this?.context?.email
          }
        });
      });
    }
  }

  render() {
    const { hasError, errorName } = this.state;
    const { children } = this.props;

    if (hasError && errorName !== 'ChunkLoadError') {
      console.log('Has rendered sentry error component');
      return <ErrorSentry blocked={errorName === 'BlockedError'} />;
    }

    return children;
  }
}

SentryComponent.propTypes = {
  children: PropTypes.node.isRequired
};

export default SentryComponent;
