import React, { useEffect, useMemo } from 'react';
import * as Sentry from '@sentry/react';
import { Amplify } from 'aws-amplify';
import { BrowserTracing } from '@sentry/tracing';
import {
  ThemeProvider,
  StyledEngineProvider,
  createTheme
} from '@mui/material';
import { createRoot } from 'react-dom/client';
import { Provider as JotaiProvider } from 'jotai';
import { frFR, enUS } from '@mui/material/locale';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line import/no-named-as-default
import posthog from 'posthog-js';

import Snackbar from './Snackbar';
import { theme } from './theme';
import App from './App';
import * as serviceWorker from './serviceWorker';
import Apollo from './Apollo';
import './i18n';
import LoaderAsync from './atoms/LoaderAsync';
import FeedbackForm from './organisms/FeedbackForm';
import awsconfig from './aws-exports-static';
import useAuth from './hooks/auth';
import LoginPage from './pages/Login';
import Gleap from 'gleap';

Amplify.configure(awsconfig[process.env.REACT_APP_STAGE || 'prod']);

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DNS,
    integrations: [new BrowserTracing()],
    environment: process.env.REACT_APP_STAGE,

    tracesSampleRate: 0.0
  });
  posthog.init('phc_wpwD9yAGi71hnYsnxyBAbA9HGtQmTdXvHy7TOvbxAKd', {
    api_host: 'https://eu.posthog.com',
    session_recording: {
      maskAllInputs: false,
      maskInputOptions: {
        password: true
      },
      maskCapturedNetworkRequestFn: request => {
        return request;
      }
    }
  });
}

const container = document.getElementById('root');
const root = createRoot(container);

const AppWrapper = () => {
  const { currentUser, loading } = useAuth();
  const { i18n } = useTranslation();

  useEffect(() => {
    if (currentUser && process.env.NODE_ENV === 'production') {
      Gleap.initialize('ZccQ2CSxhvLYPfdk4XjcrorpJV9HNfTJ');
    }
  }, [currentUser]);

  const themeWithLocale = useMemo(
    () => createTheme(theme, i18n.resolvedLanguage === 'fr' ? frFR : enUS),
    [i18n.resolvedLanguage]
  );

  if (loading) {
    return null;
  }

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={themeWithLocale}>
        <Snackbar>
          <LoaderAsync />
          {!currentUser ? <LoginPage /> : <App />}
          {!currentUser && <FeedbackForm view="float" />}
        </Snackbar>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

root.render(
  <JotaiProvider>
    <Apollo>
      <AppWrapper />
    </Apollo>
  </JotaiProvider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
