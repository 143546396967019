import React, { useRef, useState, useContext } from 'react';
import { styled } from '@mui/material/styles';
import { Link as RouterLink } from 'react-router-dom';
import { Box, ButtonBase, Menu, MenuItem, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import UserContext from '../../../context';

const PREFIX = 'Account';

const classes = {
  popover: `${PREFIX}-popover`,
  title: `${PREFIX}-title`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')(() => ({
  [`& .${classes.popover}`]: {
    width: 200
  },

  [`& .${classes.title}`]: {
    color: '#fff'
  }
}));

function Account() {
  const { t } = useTranslation();
  const ref = useRef(null);
  const [isOpen, setOpen] = useState(false);
  const { username, signOutMyself } = useContext(UserContext);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Root>
      <Box
        display="flex"
        alignItems="center"
        component={ButtonBase}
        onClick={handleOpen}
        ref={ref}
      >
        <Typography className={classes.title} variant="h6">
          {username}
        </Typography>
      </Box>
      <Menu
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        keepMounted
        PaperProps={{ className: classes.popover }}
        anchorEl={ref.current}
        open={isOpen}
      >
        <MenuItem onClick={handleClose} component={RouterLink} to="/account">
          {t('account.myAccount')}
        </MenuItem>
        <MenuItem onClick={signOutMyself}>{t('account.logout')}</MenuItem>
      </Menu>
    </Root>
  );
}

export default Account;
