import { useCallback, useContext, useMemo } from 'react';
import { useQuery, gql } from '@apollo/client';
import {
  getHours,
  getMinutes,
  isBefore,
  isSameDay,
  isToday,
  setHours,
  setMinutes,
  setSeconds,
  subMilliseconds
} from 'date-fns';
import { formatCompleteDateToString } from '../helpers/date';
import { getTimezoneOffset } from 'date-fns-tz';
import UserContext from '../context';

const GET_LOCAL_DATA = gql`
  {
    globalLoading @client
    datePickerCompareFrom @client
    datePickerCompareTo @client
    datePickerFrom @client
    datePickerTo @client
    hoursPickerFrom @client
    hoursPickerTo @client
  }
`;

// Save data to the local storage
// in order to hydrate apollo cache on first load
export const useLocalApolloData = () => {
  const { error, data, client } = useQuery(GET_LOCAL_DATA);

  const userContextData = useContext(UserContext);

  if (error) throw new Error(`Error! ${error.message}`);

  const localDateFrom = useMemo(() => {
    if (!data) {
      return null;
    }

    if (
      isSameDay(new Date(data.datePickerFrom), new Date(data.datePickerTo)) &&
      data.hoursPickerFrom !== 'null'
    ) {
      const date = `${data.datePickerFrom}T${data.hoursPickerFrom}Z`;

      return subMilliseconds(
        new Date(date),
        getTimezoneOffset(userContextData?.clientSettings.timezone, date)
      );
    }

    return data.datePickerFrom;
  }, [data, userContextData]);

  const localDateTo = useMemo(() => {
    if (!data) {
      return null;
    }

    if (
      isSameDay(new Date(data.datePickerFrom), new Date(data.datePickerTo)) &&
      data.hoursPickerTo !== 'null'
    ) {
      const date = `${data.datePickerTo}T${data.hoursPickerTo}Z`;

      return subMilliseconds(
        new Date(date),
        getTimezoneOffset(userContextData?.clientSettings.timezone, date)
      );
    }

    return data.datePickerTo;
  }, [data, userContextData]);

  const localCompareDateFrom = useMemo(() => {
    if (!data || !data?.datePickerCompareFrom) {
      return null;
    }

    return isSameDay(new Date(data.datePickerFrom), new Date(data.datePickerTo))
      ? formatCompleteDateToString(
          setHours(
            setMinutes(
              new Date(data.datePickerCompareFrom),
              getMinutes(localDateFrom)
            ),
            getHours(localDateFrom)
          )
        )
      : data.datePickerCompareFrom;
  }, [data, localDateFrom]);

  const localCompareDateTo = () => {
    if (!data || !data?.datePickerCompareTo) {
      return null;
    }

    if (isSameDay(new Date(data.datePickerFrom), new Date(data.datePickerTo))) {
      if (
        isToday(new Date(data.datePickerTo)) &&
        isBefore(new Date(), new Date(localDateTo))
      ) {
        return formatCompleteDateToString(
          setHours(
            setMinutes(
              setSeconds(new Date(data.datePickerCompareTo), 59),
              getMinutes(new Date())
            ),
            getHours(new Date())
          )
        );
      }

      return formatCompleteDateToString(
        setHours(
          setMinutes(
            setSeconds(new Date(data.datePickerCompareTo), 59),
            getMinutes(localDateTo)
          ),
          getHours(localDateTo)
        )
      );
    }

    return data.datePickerCompareTo;
  };

  const reduceGlobalLoading = useCallback(
    (type, requestId) => {
      switch (type) {
        case 'isExisting':
          return client.cache
            .readQuery({ query: GET_LOCAL_DATA })
            ?.globalLoading?.includes(requestId);
        case 'reset':
          client.cache.updateQuery({ query: GET_LOCAL_DATA }, () => ({
            globalLoading: []
          }));
          break;
        case 'remove':
          client.cache.updateQuery({ query: GET_LOCAL_DATA }, data => ({
            globalLoading: data.globalLoading.includes(requestId)
              ? data.globalLoading.filter(id => id !== requestId)
              : data.globalLoading
          }));
          break;
        case 'add':
          client.cache.updateQuery({ query: GET_LOCAL_DATA }, data => ({
            globalLoading: !data.globalLoading.includes(requestId)
              ? [...data.globalLoading, requestId]
              : data.globalLoading
          }));
          break;

        default:
          break;
      }
    },
    [client.cache]
  );

  return {
    reduceGlobalLoading,
    localGlobalLoading: data.globalLoading,
    localDateFrom,
    localDateTo,
    localCompareDateFrom,
    localCompareDateTo: localCompareDateTo(),
    localHoursPickerFrom: data.hoursPickerFrom,
    localHoursPickerTo: data.hoursPickerTo
  };
};
