import { gql } from '@apollo/client';

export const GET_LOCAL_DATA = gql`
  {
    clientId @client
    datePickerCompareFrom @client
    datePickerCompareTo @client
    datePickerFrom @client
    datePickerTo @client
    hoursPickerFrom @client
    hoursPickerTo @client
    dynamicVariables @client {
      name
      values
    }
    filterSentToPlatform @client
    filterClosingStatuses @client
    selectedAffiliatePlatformIds @client
    filterPartnerSelectedAffiliate @client
  }
`;
export const GET_CLIENTS = gql`
  query Clients {
    clients {
      id
      name
    }
  }
`;

export const GET_CLIENT_SETTINGS = gql`
  query ClientSettings($clientId: ID!) {
    client(clientId: $clientId) {
      id
      currency
      timezone
      conversionRateBase
      name
      addedAt
      attributionViews {
        id
        name
        author
        filters {
          type
          ids
        }
      }
      productsAccess {
        analytics
        affiliates
        brandSafety
      }
      dynamicVariables {
        name
        label
        values {
          label
          value
        }
      }
      bannedTransactionalFraudIds
      tag {
        isSet
        tagId
        targetsParamsBlacklist
        paymentUrls
        useForMcf
        useForExtensions
        useForOrders
        useForVouchers
        useForMetricsAndDimensions
      }
      analyticSolution {
        id
      }
      hasStoreFraudSet
      voucher {
        categories {
          id
          validVouchers
          excludeVouchers
          notAuthorizedNoVoucher
        }
        useInClosing
        useInS2S
      }
      engagementRate {
        isSet
        validDurationInSeconds
        rejectNotInSessionsForCategories
      }
      affiliatePlatforms {
        id
        name
        sendByS2s
        closing {
          hasClosingSet
          contentType
        }
        eventId {
          default
          dynamicVariables {
            value
            conditions {
              name
              value
            }
          }
        }
      }
      deduplicatedChannels {
        name
        rules {
          name
          rule
          value
        }
      }
      deduplicationRulesByCategory {
        id
        rule
      }
      catRems {
        id
        name
        variants {
          checkIsUserEngaged
          dynamicVariables {
            value
            name
          }
          affiliateValueToApply
          platformValueToApply
          typeValueToApply
        }
        defaultAffiliateValueToApply
        platformValueToApply
        typeValueToApply
      }
      closing {
        reportLastDays
        crmSettings {
          isEnabled
          contentType
          orderIdField
          statusField
          rejectStatusRegex
          waitingStatusRegex
          validStatusRegex
          userEngagementField
          userEngagementRegex
          dateBeginningField
          dateBeginningFormat
          fieldsToCopy
        }
      }
      weeklySummaryRecipients
      gdprSettings {
        showPage
      }
      affiliationSettings {
        inUse
        deduplicatedChannelIdsFromAttribution
        attributionModel {
          affiliatePlatforms {
            rule
          }
        }
      }
      attributionPingsSettings {
        visitDefinition
        isMarginKpiActive
        startDate
        inUse
        isConnectorPageActive
        isActiveExemptedData
        conversionMethodology
        sanitazeParamsTarget
        shouldRemoveHashFromTarget
        keepParamsTarget
        customAttributionModels {
          id
          name
        }
        manualCosts {
          id
          name
        }
        backOfficeImports {
          settings {
            file {
              format
              dateFormat
              conversionRegex
              columnsMapping {
                conversionId
                conversionDate
                conversionAmount
                conversionMargin
              }
              timezone
              dimensionsMapping {
                column
                value
              }
            }
            conversionsDefinition {
              valid {
                column
                value
                operator
              }
              waiting {
                column
                value
                operator
              }
              canceled {
                column
                value
                operator
              }
            }
            importDefinition {
              column
              value
              operator
            }
          }
        }
        productsDynamicVariable {
          inUse
          variableName
          attributs {
            price
            quantity
            id
            name
            discount
            brand
            category
            voucher
            variant
            location
          }
        }
        pingFilters {
          id
          name
          rules {
            id
            value
          }
        }
        conversionDeduplication {
          isActive
        }
        connectors {
          id
          type
          provider
          lastAuthorizedCheck
          isAuthorized
          isAuthorizedMessage
          customerId
          email
          accountId
          accountName
          viewId
          tableNameSuffix
          lastDayChecked
          lastUpdateDate
          lastUpdateConnectorDate
          connectorAuthentificationEmail
          connectorAuthentificationId
          disableSynchronization
          campaignIdsToIgnore
          channels {
            channelId
            subChannelId
            matchCampaignsInDetails
            conversionGoals
            audienceDimensions {
              id
              campaigns {
                id
                value
              }
            }
            manualCuttingValuesMatching {
              cuttingValue
              externalCampaignName
            }
          }
        }
        customDashboards {
          type
          view
          data
          split
          splitDataType
          splitVariable
          size
          priority
        }
        isActiveCustomModelWeights
        customModelWeights {
          id
          weight
          weightFirst
          subChannels {
            id
            weight
            weightFirst
          }
        }
        customAttributes {
          id
          name
          type
          internalAttributKey
          personalData
          ecommerceObjectIndex
          dimension
          ecommerceCustomization {
            productsListTitle
            categoriesListTitle
            brandsListTitle
          }
          ecommerceObject {
            id
            id_personalData
            price
            price_personalData
            quantity
            quantity_personalData
            name
            name_personalData
            brand
            brand_personalData
            category
            category_personalData
          }
        }
        channels {
          id
          name
          priority
          cutting {
            area
            source
            nameParam
            valueCleanRegex
          }
          rules {
            area
            valueParamRegex
            source
            nameParam
          }
          subChannels {
            id
            name
            priority
            rules {
              area
              valueParamRegex
              source
              nameParam
            }
          }
        }
      }
    }
  }
`;
