import { gql } from '@apollo/client';
import i18next from 'i18next';

import { useCustomQuery } from './graphql';

const GET_AFFILIATE_CATEGORIES = gql`
  query AffiliateCategories {
    affiliateCategories {
      id
      name {
        en
        fr
      }
    }
  }
`;

// Custom hook to get all the affiliate categories
export const useAffiliateCategory = (skip = false) => {
  const { data } = useCustomQuery(GET_AFFILIATE_CATEGORIES, {
    showLoader: false,
    fetchPolicy: 'cache-first',
    skip
  });

  const findCategoryNameFromId = categoryId =>
    data?.affiliateCategories?.find(({ id }) => id === categoryId)?.name?.[
      i18next.resolvedLanguage
    ] || null;

  const getAllCategories =
    data?.affiliateCategories?.map(item => ({
      id: item.id,
      name: item.name[i18next.resolvedLanguage]
    })) || [];

  return { findCategoryNameFromId, getAllCategories };
};
